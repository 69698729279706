export const loginInitialState: ILoginState = {
  authInfo: {
    additionalInfo: {
      BrideAdministrator: '',
      BrideUser: '',
      SecurityAuditor: '',
      amr: '',
      at_hash: '',
      aud: [],
      auth_time: 0,
      email_verified: '',
      idp: '',
      iss: '',
      jti: '',
      nbf: '',
      nonce: '',
      sub: '',
      cid: ''
    },
    adminPlan: '',
    brand: '',
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    plans: [],
    roles: [],
    scope: [],
    tokenExpirationTime: 0,
    tokenIssueTime: 0,
    tokenValidDuration: 0,
  },
  isFetchingAuthInfo: false,
  userAuthorizations: {
    userId: '',
    userAdminPlansAndPlans: {},
    roles: [],
    resources: []
  },
  selectedAdminPlan: ''
};

export interface ILoginState {
  authInfo: IAuthInfo;
  isFetchingAuthInfo: boolean;
  userAuthorizations: IUserAuthorizations;
  selectedAdminPlan: string;
}

export interface IAuthInfo {
  additionalInfo?: IAddtionalInfo;
  adminPlan?: string;
  brand?: string;
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  plans?: string[];
  roles?: string[];
  scope?: string[];
  tokenExpirationTime?: number;
  tokenIssueTime?: number;
  tokenValidDuration?: number;
  impersonationUser?: string;
  expirationTime?: number;
  cid?: string;
}

export interface IAddtionalInfo {
  BrideAdministrator: string;
  BrideUser: string;
  SecurityAuditor: string;
  amr: string;
  at_hash: string;
  aud: string[];
  auth_time: number;
  email_verified: string;
  idp: string;
  iss: string;
  jti: string;
  nbf: string;
  nonce: string;
  sub: string;
  cid: string;
}

export interface IUserAuthorizations {
  userId: string;
  roles: string[];
  resources: string[];
  userAdminPlansAndPlans: {[key: string]: string[]}
}

export interface IAdminplan extends IValueFormat {
  childContexts: {
    PLAN: IValueFormat [];
  };
}

export interface IValueFormat {
  value: string; format: string;
}

export interface ISolutionsState {
  isAuthenticated: boolean;
  isLoginFailed: boolean;
  noAuthroziations: boolean;
  moreUserRoles: string;
  timeOutError: string;
  noContext: string;
}
export const initialSolutionState: ISolutionsState = {
  isAuthenticated: false,
  isLoginFailed: false,
  noAuthroziations: false,
  moreUserRoles: '',
  timeOutError: '',
  noContext: ''
};
