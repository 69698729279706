import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib';
import { IReportsState } from '../store/reports/state';
import { ReportsComponent } from './reports.component';
import { IAppAuthorizations, IUiAuthorizations } from '../authorizations/state';
import { getRelaxedUiAuth } from 'src/app/authorizations/selector';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reports',
  template: '<ng-container #reportsComponent></ng-container>'
})
export class ReportsContainer implements OnInit {
  luxId = 'BMT-Reports-Component' + this.constructor.name;
  featureHeadingMapping = {
    overageDependentNotice: 'Notice',
    overageDependentAdhoc: 'Adhoc Report',
    overageDependentConsolidated: 'Term Consolidated Report',
    customGroupReport: 'customGroupReport'
  };
  @ViewChild('reportsComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: ReportsComponent;
  relaxedAuth$: Observable<IUiAuthorizations | {}> = this.lux.get(getRelaxedUiAuth, this.luxId);

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private lux: Lux<{ reportsState: IReportsState, appUiAuth: IAppAuthorizations }>) {
  }

  ngOnInit() {
    this._loadComponent();
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      ReportsComponent
    );

    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;
    this.relaxedAuth$.subscribe(uiAuth => {
        this.componentInstance.reportFeatures = (uiAuth as IUiAuthorizations).reports;
        this.componentInstance.reportFeatures && this.componentInstance.reportFeatures.includes('overageDependentNotice') ?
          this.componentInstance.chosenReportAction = this.componentInstance.subHeader = this.featureHeadingMapping.overageDependentNotice :
          this.componentInstance.subHeader = this.featureHeadingMapping.customGroupReport;
      }
    );
  }

}
