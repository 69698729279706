import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../shared/app.config';
import * as momentNS from 'moment';

const moment = momentNS;

@Component({
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent implements OnInit, OnDestroy {
  region = this.config.region;
  disableSwitchMember: boolean;
  memberSelected: any;
  clientSelected: any;
  actionSelected: string;
  transferVisible: string;
  showBackToResults = false;
  clientDetails = null;
  selectedAdminPlan = '';
  playerUnderVsp = ['DDPMI', 'DDPOH', 'DDPIN'];
  @Output() setMemberStore: EventEmitter<any> = new EventEmitter();

  constructor(private route: Router, private config: AppConfig) {
  }

  ngOnInit() {
  }

  goToMemberSearch() {
    this.route.navigate(['eligibility/member']);
  }

  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView();
  }
  headerSelectedFromFamilySection(event) {
    if (typeof event !== 'string') {
      this.setMemberStore.emit({
        addMemberFeed: {
          subscriberName: event.firstName + ' ' + event.lastName,
          planAcronym: event.planAcronym,
          clientId: event.clientSpecifiedIdentifier,
          subClientId: event.subclientSpecifiedIdentifier,
          memberId: '',
          subscriberEnrollmentID: event.subscriberEnrollmentId,
          asOf: moment(event.eligibilityEffectiveDate).isAfter(moment(event.asOf || new Date()).format('YYYY-MM-DD')) ? event.eligibilityEffectiveDate : event.asOf
        }
      });
      this.route.navigate(['/add-member']);
    }
  }

  headerSelectedFromMemberInfo(event) {
    const CONDITION_TO_NOT_CHECK = ['success', 'cancelled', 'terminate', 'reinstate', 'transfer', 'copy'];
    // look for 'edit', 'success', 'cancelled'
    this.disableSwitchMember = (event === 'edit') || !(CONDITION_TO_NOT_CHECK.indexOf(event) > -1);
    // To show the terminate/reinstate flow
    (event === 'terminate' || event === 'reinstate') && (this.actionSelected = event);
    // To show the Transfer or Copy flow
    // this.transferVisible = (event === 'transfer') || (event === 'copy');
    (event === 'transfer' || event === 'copy') && (this.transferVisible = event);
    const not_track = ['success', 'cancelled'];
    !(not_track.includes(event));
  }

  selectedMember(member) {
    this.memberSelected = member.value ? member.value : member;
  }

  // Listening to termimnate/reinstate output and clearing the trigger. So, that we can recall it.
  wasTerminatePerformaed(was) {
    if (was) {
      this.actionSelected = '';
    }
  }

  transferOrCopyDone(event) {
    this.transferVisible = '';
    if (typeof event !== 'string') {
      this.clientDetails = null;
      this.clientSelected = {
        planAcronym: event.planAcronym,
        clientSpecifiedId: event.clientSpecifiedId,
        subClientSpecifiedId: event.subClientSpecifiedId
      };
    }
  }

  ngOnDestroy() {
  }
}
