import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Lux } from '@roosevelt/common-ui-lib';
import { Observable, Subscription } from 'rxjs';
import { CreateManageUserAsync } from 'src/app/store/createManageUser/async';
import {
  errorsfetchingUserInfo,
  errorsUpdatingUser,
  fetchingUserInfo,
  getUserLists,
  getUsesrlistErrors,
  isFetchingUserLists,
  selectedUserInfo,
  updatingUserInfo,
  updatingUserStatus
} from 'src/app/store/createManageUser/selector';
import { IAdminUserList, ICreateManageUserState, IUserInfoObj, IUserList } from 'src/app/store/createManageUser/state';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'bmt-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit, OnDestroy {
  luxId = 'BMT-manage-user' + this.constructor.name;

  getErrors$: Observable<string> = this.lux.get(getUsesrlistErrors, this.luxId);
  isFetchingUserLists$: Observable<boolean> = this.lux.get(isFetchingUserLists, this.luxId);
  getUserLists$: Observable<IAdminUserList> = this.lux.get(getUserLists, this.luxId);
  updatingUserInfo$: Observable<boolean> = this.lux.get(updatingUserInfo, this.luxId);
  updatingUserStatus$: Observable<string> = this.lux.get(updatingUserStatus, this.luxId);
  errorsUpdatingUser$: Observable<string> = this.lux.get(errorsUpdatingUser, this.luxId);

  fetchingUserInfo$: Observable<boolean> = this.lux.get(fetchingUserInfo, this.luxId);
  selectedUserInfo$: Observable<IUserInfoObj[]> = this.lux.get(selectedUserInfo, this.luxId);
  errorsfetchingUserInfo$: Observable<string> = this.lux.get(errorsfetchingUserInfo, this.luxId);

  users: IUserList[] = [];
  isFetchingUserList: boolean;
  fetchingUserErrors: string;
  totalUsers = 0;
  currentPage = 0;
  defaultPageSize = 25;
  selectedIndex = 0;
  sortField: string;
  sortOrder = 'ASC';
  manageUserInfo: IUserList;
  updateUserForm: UntypedFormGroup;
  updatingUserInfo: boolean;
  updatingUserStatus: string;
  errorsUpdatingUser: string;
  errorsfetchingUserInfo: string;
  fetchingUserInfo: boolean;
  formSnapShot: string;
  areThereAnyChanges = false;
  formValueSub$: Subscription;

  constructor(private router: Router,
              private fb: UntypedFormBuilder,
              private lux: Lux<{ createManageUserState: ICreateManageUserState }>,
              private createManageUserAsync: CreateManageUserAsync) {
  }

  ngOnDestroy(): void {
    this.formValueSub$ && this.formValueSub$.unsubscribe();
    this.lux.destroy(this.luxId);
  }

  ngOnInit(): void {
    this.getUsersList(0);
    this.isFetchingUserLists$.subscribe(isFetching => this.isFetchingUserList = isFetching);
    this.getUserLists$.subscribe(usersResponse => {
      this.totalUsers = usersResponse.pagination.totalRecords;
      this.users = usersResponse.users;
    });
    this.getErrors$.subscribe(errors => this.fetchingUserErrors = errors);
    this.updatingUserInfo$.subscribe(updating => this.updatingUserInfo = updating);
    this.updateUserForm = this.fb.group({
      userIdentifier: [{
        value: this.manageUserInfo ? this.manageUserInfo.userName : '',
        disabled: true
      }],
      lastName: ['', [Validators.pattern('^[a-zA-Z.\'-]+( +[a-zA-Z.\'-]+)*$'), Validators.required, Validators.maxLength(50)]],
      firstName: ['', [Validators.pattern('^[a-zA-Z.\'-]+( +[a-zA-Z.\'-]+)*$'), Validators.required, Validators.maxLength(50)]],
      companyName: ['', [Validators.required, Validators.maxLength(150)]],
      emailAddress: [this.manageUserInfo ? this.manageUserInfo.emailAddress : '', [Validators.maxLength(100), Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$'), Validators.required]]
    });
    this.errorsUpdatingUser$.subscribe(errors => this.errorsUpdatingUser = errors);
    this.updatingUserStatus$.subscribe(updateStatus => {
      this.updatingUserStatus = updateStatus;
      // Getting the latest user info if it is enable or disable only.
      if (updateStatus && updateStatus !== 'user data updated') {
        this.getUsersList(this.currentPage > 1 ? (this.currentPage - 1) * this.defaultPageSize : 0);
      }
    });
    this.fetchingUserInfo$.subscribe(fetching => this.fetchingUserInfo = fetching);
    this.errorsfetchingUserInfo$.subscribe(errors => this.errorsfetchingUserInfo = errors);
  }

  getUsersList(offset) {
    const adminUserSreq = {
      pagination: {
        // Might have to tweak the below shitty logic
        offset: (this.sortField && this.sortOrder === 'DESC') ?
        ((this.totalUsers+1) - (this.currentPage >= 1 ? (this.currentPage * this.defaultPageSize)+1 : this.defaultPageSize)) :
        this.currentPage >= 1 ? offset > this.totalUsers ? (offset - this.totalUsers) : offset : offset,
        limit: this.defaultPageSize
      },
      ...this.sortField && {sort: [{
        order: this.sortOrder,
        fieldName: 'users.' + this.sortField
      }]},
      searchType: 'children'
    };
    this.lux.set(state => state.createManageUserState, {adminUsersReq: adminUserSreq});
    this.createManageUserAsync.getUsersForAdmin().subscribe();
  }

  getMoreUsers(page) {
    this.currentPage = page.pageIndex;
    this.getUsersList(page.pageIndex >= 1 ? (page.pageIndex * this.defaultPageSize)+1 : 0);
  }

  sortTheUserList(field) {
    this.sortOrder = field !== this.sortField ? 'DESC' : this.sortOrder !== 'ASC' ? 'ASC' : 'DESC';
    (field !== this.sortField) && (this.sortField = field);
    const sort = {
      order: this.sortOrder,
      fieldName: 'users.' + this.sortField
    };
    // this.currentPage = 1;
    // this.getUsersList(0);
    this.getUsersList(this.currentPage >= 1 ? (this.currentPage * this.defaultPageSize) : 0);

  }

  navigateByAction(action, user) {
    if (action !== 'manage') {
      this.lux.set(state => state.createManageUserState.manageUser, {
        selectedAction: action,
        selectedUserId: user.userName,
        selectedUserType: user.userType || 'User'
      });
      this.router.navigate(['/manage-user']);
    } else {
      this.manageUserInfo = {...user};
      this.openUpdateUserInfo();
    }
  }

  openUpdateUserInfo() {
    const req = {
      id: this.manageUserInfo.userName,
      status: this.manageUserInfo.userStatus === 'Enabled' ? 'A' : 'I'
    };
    this.updatingUserStatus = '';
    this.errorsUpdatingUser = '';
    this.createManageUserAsync.getUserInfo(req).subscribe(userData => {
      // Setting the available info
      this.updateUserForm.controls.userIdentifier.setValue(this.manageUserInfo.userName || '');
      this.updateUserForm.controls.companyName.setValue(userData[0].companyName || '');
      this.updateUserForm.controls.lastName.setValue(userData[0].lastName || '');
      this.updateUserForm.controls.firstName.setValue(userData[0].firstName || '');
      this.updateUserForm.controls.emailAddress.setValue(userData[0].emailAddress || '');
      this.formSnapShot = JSON.stringify(this.updateUserForm.value);
      this.formValueSub$ = this.updateUserForm.valueChanges.pipe(startWith(this.updateUserForm.value)).subscribe(value => {
        if (this.updateUserForm.valid) {
          this.areThereAnyChanges = (this.formSnapShot !== JSON.stringify(value));
        }
      });
    });
  }

  updateUserInfo() {
    const request = {userIdentifier : this.manageUserInfo.userName, ...this.getFieldsModified(this.updateUserForm)};
    this.createManageUserAsync.updateUserInfo(request).subscribe();
  }

  enableDisableUser(userName, disable) {
    this.createManageUserAsync.enableDisableUser(userName, disable).subscribe();
  }

  getFieldsModified(form: any) {
    const modifiedValues = {};
    Object.keys(form.controls).forEach(key => {
      const currentControl = form.controls[key];
      if (currentControl.dirty) {
        modifiedValues[key] = currentControl.value;
      }
    });
    return modifiedValues;
  }

  resetUpdateUserForm() {
    this.manageUserInfo = null;
    this.updateUserForm.reset();
  }
}
