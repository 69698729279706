import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DentistDirectoryComponent } from './dentist-directory.component';
import { DentistDirModule as DDLibModule } from '@roosevelt/dentist-dir-ui-lib';


@NgModule({
  declarations: [DentistDirectoryComponent],
  imports: [
    CommonModule,
    DDLibModule
  ]
})
export class DentistDirectoryModule { }
