import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';
import { IConsolidatedReq, IMassActionsReports } from './state';
import { BasicAuthConfig } from '@roosevelt/common-ui-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsApiService {

  constructor(private http: HttpClient, private config: AppConfig, private internalAuth: BasicAuthConfig) {
  }

  massActionsReport() {
    return this.http.get<IMassActionsReports>(this.config.massActionsReportUrl);
  }

  getODNotice(req) {
    return this.http.post<any>(this.config.overageDependentNoticeUrl, req);
  }

  getDocument(req): Observable<any> {
    const url = this.config.overageDependentNoticeDocUrl + req.id;
    if (this.internalAuth.authedUrls.indexOf(url) === -1) {
      this.internalAuth.authedUrls = [...this.internalAuth.authedUrls, url];
    }
    return this.http.get(url, { observe: 'body', responseType: 'arraybuffer' });
  }

  getAdhocReport(req) {
    return this.http.post(this.config.adhocReportUrl, req, {
      headers: { accept: 'application/vnd.ms-excel' },
      observe: 'body',
      responseType: 'arraybuffer'
    });
  }

  getConsolidatedReport(req: IConsolidatedReq) {
    return this.http.post(this.config.consolidatedReportUrl, req, {
      headers: { accept: 'application/vnd.ms-excel' },
      observe: 'body',
      responseType: 'arraybuffer'
    });
  }

  getCustomGroupReport(req) {
    return this.http.post<any>(this.config.customGroupReportUrl, req);
  }
}
