import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingComponent } from './billing.component';
import { ButtonModule, CheckboxModule, DialogModule, InputModule, LoaderModule, Lux, RadioModule, SelectModule } from '@roosevelt/common-ui-lib';
import { IBmtBillingState } from '../store/billing/state';
import { initialStateMaBillingState } from '../store/billing/initialState';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientSearchModule } from '@roosevelt/client-ui-lib';
import {AvailableReportsModule,BillingModule as BillingLibraryModule} from '@roosevelt/billing-lib'
import { BillingContainer } from './billing.container';
import { AchPaymentComponent } from './ach-payment/ach-payment.component';
import { AchPayerAddressComponent } from './ach-payer-address/ach-payer-address.component';

@NgModule({
  declarations: [BillingComponent,BillingContainer,AchPaymentComponent,AchPayerAddressComponent],
  imports: [
    CommonModule,
    ButtonModule,
    InputModule,
    RadioModule,
    ReactiveFormsModule,
    ClientSearchModule,
    LoaderModule,
    FormsModule,
    BillingLibraryModule,
    AvailableReportsModule,
    SelectModule,
    LoaderModule,
    DialogModule,
    CheckboxModule
  ]
})
export class BillingModule {
    constructor(lux: Lux<{ billingState: IBmtBillingState }>) {
      const billingState = JSON.parse(sessionStorage.getItem('ma-billing-state'));
      lux.register('billingState', billingState ? billingState : initialStateMaBillingState);
      lux.allChanges().subscribe(state => sessionStorage.setItem('ma-billing-state', JSON.stringify(state.billingState)));
    }
  }