export const initialMassActionState: IMassActionState = {
  isCallingMassAction: false,
  isMassActionCallDone: false,
  isShowDestinationClient: false,
  massTerminateOrReinstateReq: {
    planAcronym: '',
    reinstateAllSubscribers: false,
    terminateAllSubscribers: false,
    eligibilityStatus: '',
    eligibilityStatusReason: '',
    subClientSpecifiedIdentifiers: [],
    clientSpecifiedIdentifier: '',
    receivedDate: '',
    effectiveDate: '',
    sourceEligibilityEffectiveDate: '',
    dateRules: {
      overrideEligibilityDateRule: false,
      overrideRetroEligibilityDateRule: false,
      overrideClaimPaymentDateRule: false
    }
  },
  massTransferReq: {
    sourcePlanAcronym: '',
    destinationPlanAcronym: '',
    receivedDate: '',
    effectiveDate: '',
    transferAllSubscribers: false,
    transferAllDependents: false,
    SourceEligibilityEffectiveDate: '',
    sourceSubClientSpecifiedIdentifiers: [],
    sourceClientSpecifiedIdentifier: '',
    destinationSubClientSpecifiedIdentifier: '',
    destinationClientSpecifiedIdentifier: '',
    dateRules: {
      overrideEligibilityDateRule: false,
      overrideRetroEligibilityDateRule: false,
      overrideClaimPaymentDateRule: false
    }
  },
  sourceSubClients: [],
  destinationSubClients: [],
  sourceClient: {
    id: 0,
    planAcronym: '',
    specifiedId: '',
    name: '',
    status: {
      code: '',
      value: '',
      effectiveDate: ''
    }
  },
  destinationClient: {
    id: 0,
    planAcronym: '',
    specifiedId: '',
    name: '',
    status: {
      code: '',
      value: '',
      effectiveDate: ''
    }
  },
  errorMessage: '',
  actionSelected: 'Terminate',
  contractInfo: {
    isFecthingContractInfo: false,
    requestObj: {
      planAcronym: '',
      client: {
        specifiedId: '',
      },
      subClient: {
        specifiedId: '',
      },
      asOf: '',
      view: []
    },
    responseObj: {
      client: {
        billingclienttype: {
          code: '',
          value: '',
        },
        groupHealthPlanCertificationOnfile: '',
        id: 0,
        idCardConfig: {
          autoGenerate: '',
          cardFormat: {
            code: '',
            value: ''
          },
          idCardRequired: '',
          mailTo: {
            code: '',
            value: ''
          },
          printOptions: {
            clientName: {
              code: '',
              value: ''
            },
            subClientSpecifiedInd: '',
            subscriberNameInd: '',
            subscriberAltId: '',
            alternateName: ''
          },
          otherMaterials: []
        },
        name: '',
        planAcronym: '',
        previserWellnessType: {},
        publicEntitlementType: {},
        specifiedId: '',
        status: {
          code: '',
          effectiveDate: '',
          value: '',
        },
        subClient: {
          caidLength: '',
          caidSource: {
            code: '',
            value: '',
          },
          groupHealthPlanCertificationOnfile: '',
          contract: {
            benefitsConfig: {
              accumulationPeriod: {
                code: '',
                value: '',
              },
              benefitPeriod: {
                endDate: '',
                startDate: ''
              },
              hasWaitingPeriodComponent: ''
            },
            cobConfig: {
              approvedAmountCalcType: {
                code: '',
                value: '',
              },
              indicators: {
                benefitReserve: '',
                external: '',
                internal: '',
                tracking: ''
              },
              members: {
                external: [],
                internal: []
              },
              paymentOption: {
                code: '',
                value: '',
              },
              paymentOrder: {
                code: '',
                value: '',
              },
            },
            eligAgeLimitsConfig: {
              child: {
                maxAgeLimit: {
                  limit: 0,
                  type: {
                    code: '',
                    value: '',
                  }
                },
                minAgeLimit: {
                  limit: 0,
                  type: {
                    code: '',
                    value: '',
                  }
                }
              },
              irs: {
                maxAgeLimit: {
                  limit: 0,
                  type: {
                    code: '',
                    value: '',
                  }
                }
              },
              overage: {
                autoTerm: '',
                notificationTimeLimit: {
                  duration: {
                    code: '',
                    value: '',
                  },
                  limit: 0
                },
                returnVerifTo: {
                  code: '',
                  value: '',
                },
                sendNotification: '',
              },
              student: {
                maxAgeLimit: {
                  limit: 0,
                  type: {
                    code: '',
                    value: '',
                  }
                }
              },
              studentDocumentRequired: ''
            },
            eligibilityConfig: {
              coverageTypes: [],
              enrollmentTypes: {
                code: '',
                value: '',
              },
              lateEntrant: '',
              retroActiveEligibilityLimit: {
                limit: '',
                timeType: '',
                value: ''
              },
              specialAttributeTypes: [],
            },
            endDate: '',
            fundingType: {
              code: '',
              value: '',
            },
            hasGraduatingBenefits: false,
            id: 0,
            restrictionConfig: { creditRestriction: '' },
            restrictionType: {
              code: '',
              value: '',
            },
            service: {
              code: '',
              value: '',
            },
            startDate: '',
          },
          id: 0,
          name: '',
          planAcronym: '',
          previserWellnessType: {},
          publicEntitlementType: {},
          specifiedId: '',
          status: {
            code: '',
            effectiveDate: '',
            value: '',
          },
          supportsCaid: false,
          supportsDai: false,
          supportsSsn: false,
        },
        type: {
          code: '',
          value: '',
        },
        underwritingPlanAcronym: '',
      }
    },
    errors: ''
  }
};

export interface IMassActionState {
  isCallingMassAction: boolean;
  isMassActionCallDone: boolean;
  massTerminateOrReinstateReq: IMassTerminateOrReinstate;
  massTransferReq: IMassTransfer;
  errorMessage: string;
  actionSelected: string;
  sourceClient: IClient;
  sourceSubClients: ISubClient[];
  destinationClient: IClient;
  destinationSubClients: ISubClient[];
  isShowDestinationClient: boolean;
  contractInfo: {
    isFecthingContractInfo: boolean;
    requestObj: IContractReqObj;
    responseObj: IClientPlanDetails;
    errors: string
  };
}

export interface IContractReqObj {
  planAcronym: string | string[];
  client: {
    specifiedId: string;
  };
  subClient: {
    specifiedId: string;
  };
  asOf: string;
  view: string[];
}

export interface IContractResponse {
  endDate: string;
  startDate: string;
}

export interface IClientPlanDetails {
  client: {
    billingclienttype : ICodeValue;
    groupHealthPlanCertificationOnfile: string;
    id: number;
    idCardConfig: IIdCardConfig;
    name: string;
    planAcronym: string;
    previserWellnessType: {};
    publicEntitlementType: {};
    routeCondition?: IReason[];
    specifiedId: string;
    status: IStatus;
    subClient: ISubClientDetails;
    type: ICodeValue;
    underwritingPlanAcronym: string;
  }
}

export interface IIdCardConfig {
  autoGenerate: string;
  cardFormat: ICodeValue;
  externalFulfillment?: ICodeValue;
  idCardRequired: string;
  mailTo: ICodeValue;
  printOptions: IPrintOptions;
  otherMaterials: IOtherMaterials[];
}

export interface IPrintOptions {
  clientName: ICodeValue;
  subClientSpecifiedInd: string;
  subscriberNameInd: string;
  subscriberAltId: string;
  alternateName: string;
}

export interface IOtherMaterials {
  code: string;
  description: string;
  value: string;
  text: string;
}

export interface IReason {
  reason: ICodeValue;
  startDate: string;
}
export interface ISubClientDetails {
  caidLength: string;
  caidSource: ICodeValue;
  contract: IContract;
  groupHealthPlanCertificationOnfile: string;
  id: number;
  idCardConfig?: IIdCardConfig;
  name: string;
  planAcronym: string;
  previserWellnessType: {};
  publicEntitlementType: {};
  routeCondition?: IReason[];
  specifiedId: string;
  status: IStatus;
  supportsCaid: boolean;
  supportsDai: boolean;
  supportsSsn: boolean;
}

export interface IContract {
  benefitsConfig: IBenefitsConfig;
  cobConfig: ICobConfig;
  eligAgeLimitsConfig: IEligAgeLimitConfig;
  eligibilityConfig: IEligibilityConfig;
  endDate: string;
  fundingType: ICodeValue;
  hasGraduatingBenefits: boolean;
  id: number;
  restrictionConfig: IRestrictionConfig;
  restrictionType: ICodeValue;
  service: ICodeValue
  startDate: string;
}

export interface IBenefitsConfig {
  accumulationPeriod: ICodeValue;
  benefitPeriod: IStartEndDate;
  hasWaitingPeriodComponent: string;
  waitPeriodCredit?: ICodeValue;
  missingToothExclusion?: {
    missingToothInd: any;
    applyWPCredit: string;
    timePeriod: number
  }
}

export interface ICobConfig {
  approvedAmountCalcType: ICodeValue;
  indicators: IIndicators;
  members: ICobConfigMember;
  paymentOption: IPaymentOption;
  paymentOrder: ICodeValue;
  recertifyStartDate?: string;
}

export interface IPaymentOption {
  alternateOption?: {
    code: string;
    procedureNodes: {};
    value: string;
  },
  code: string;
  value: string;
}

export interface IEligAgeLimitConfig {
  child: {
    maxAgeLimit: IMaxMinAgeLimit;
    minAgeLimit: IMaxMinAgeLimit;
  };
  irs: {
    maxAgeLimit: IMaxMinAgeLimit;
  };
  overage: {
    autoTerm: string,
    notificationTimeLimit: {
      duration: ICodeValue;
      limit: number;
    };
    returnVerifTo: ICodeValue;
    sendNotification: string;
  };
  student: {
    maxAgeLimit: IMaxMinAgeLimit;
  };
  studentDocumentRequired: string;
}

export interface IEligibilityConfig {
  coverageTypes: ICodeValue[];
  enrollmentTypes: ICodeValue;
  lateEntrant: string;
  retroActiveEligibilityLimit: {
    limit: string;
    timeType: string;
    value: string;
  };
  specialAttributeTypes: ICodeValue[];
}

export interface IRestrictionConfig {
  creditRestriction: string;
}

export interface IIndicators {
  benefitReserve: string,
  external: string;
  internal: string;
  tracking: string;
  recertify?: string;
}

export interface ICobConfigMember {
  external?: ICodeValue[];
  internal?: ICodeValue[];
}

export interface IMaxMinAgeLimit {
  limit: number;
  type: ICodeValue;
}

export interface ICodeValue {
  code: string;
  value: string;
}

export interface IStatus {
  code: string;
  effectiveDate: string;
  value: string;
}

export interface IStartEndDate {
  endDate: string;
  startDate: string;
}

export interface IClient {
  name?: string;
  planAcronym: string | string[];
  specifiedId: string;
  id: number;
  status?: IStatus;
  selected?: boolean;
}

export interface ISubClient {
  planAcronym: string;
  id: number;
  specifiedId: string;
  name: string;
  status: IStatus;
  selected?: boolean;
  client?: IClient;
}

export interface IStatus {
  code: string;
  value: string;
  effectiveDate: string;
}
export interface IMassTerminateOrReinstate {
  planAcronym: string;
  reinstateAllSubscribers?: boolean;
  terminateAllSubscribers?: boolean;
  eligibilityStatus: string;
  eligibilityStatusReason: string;
  subClientSpecifiedIdentifiers: string[];
  clientSpecifiedIdentifier: string;
  receivedDate: string;
  effectiveDate: string;
  sourceEligibilityEffectiveDate: string;
  dateRules: IDateRules;
}
export interface IMassTransfer {
  sourcePlanAcronym: string;
  destinationPlanAcronym: string;
  receivedDate: string;
  effectiveDate: string;
  transferAllSubscribers: boolean;
  transferAllDependents: boolean;
  SourceEligibilityEffectiveDate: string;
  sourceSubClientSpecifiedIdentifiers: string[];
  sourceClientSpecifiedIdentifier: string;
  destinationSubClientSpecifiedIdentifier: string;
  destinationClientSpecifiedIdentifier: string;
  dateRules: IDateRules;
}

export interface IDateRules {
  overrideEligibilityDateRule: boolean;
  overrideRetroEligibilityDateRule: boolean;
  overrideClaimPaymentDateRule: boolean;
}

export interface ISubscriberName {
  name: {
    first: string,
    last: string
  };
}
