import { Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { SummaryDetailsComponent } from './summary-details.component';
import { Lux } from '@roosevelt/common-ui-lib';
import {
  IAddressHistory,
  ICOBHistory,
  ICoverageHistory,
  IEligHistory,
  IPersonHistory,
  ISummaryOfChangesState, ITransactions
} from '../../store/summaryOfChanges/state';
import { Observable } from 'rxjs';
import {
  getAddressHistoryResponse,
  getCobSearchResponse,
  getCoverageResponse,
  getMemberEligResponse,
  getPersonHistoryResponse, getSelectedTransaction
} from '../../store/summaryOfChanges/selector';
import { initialStateSummaryOfChange } from '../../store/summaryOfChanges/initialState';

@Component({
  selector: 'app-summary-details',
  template: '<ng-container #SummaryDetailsComponent></ng-container>'
})
export class SummaryDetailsContainer implements OnInit {
  luxId = 'BMT-SummaryDetails-Comp'+this.constructor.name;
  @Output() backToTransactions = new EventEmitter<any>();
  @ViewChild('SummaryDetailsComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: SummaryDetailsComponent;
  getSelectedTransaction$: Observable<ITransactions> = this.lux.get(getSelectedTransaction, this.luxId);
  getMemberEligResponse$: Observable<IEligHistory[]> = this.lux.get(getMemberEligResponse, this.luxId);
  getCobSearchResponse$: Observable<ICOBHistory[]> = this.lux.get(getCobSearchResponse, this.luxId);
  getCoverageResponse$: Observable<ICoverageHistory[]> = this.lux.get(getCoverageResponse, this.luxId);
  getPersonHistoryResponse$: Observable<IPersonHistory[]> = this.lux.get(getPersonHistoryResponse, this.luxId);
  getAddressHistoryResponse$: Observable<IAddressHistory[]> = this.lux.get(getAddressHistoryResponse, this.luxId);

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private lux: Lux<{ sChanges: ISummaryOfChangesState }>) {
  }

  ngOnInit(): void {
    this._loadComponent();
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      SummaryDetailsComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;
    this.componentInstance.backSearch.subscribe(this.backSearch.bind(this));
    this.getSelectedTransaction$.subscribe(x => this.componentInstance.transaction = x);
    this.getMemberEligResponse$.subscribe(x => this.componentInstance.memberEligHistory = x);
    this.getCobSearchResponse$.subscribe(x => this.componentInstance.cobHistory = x);
    this.getCoverageResponse$.subscribe(x => this.componentInstance.coverageHistory = x);
    this.getPersonHistoryResponse$.subscribe(x => this.componentInstance.personHistory = x);
    this.getAddressHistoryResponse$.subscribe(x => this.componentInstance.addressHistory = x);
  }

  backSearch() {
    this.backToTransactions.emit();
    this.lux.set(state => state.sChanges, {
      selectedTransaction: initialStateSummaryOfChange.selectedTransaction,
      memberEligHistoryRes: initialStateSummaryOfChange.memberEligHistoryRes,
      cobHistoryRes: initialStateSummaryOfChange.cobHistoryRes,
      coverageHistoryRes: initialStateSummaryOfChange.coverageHistoryRes,
      personHistoryRes: initialStateSummaryOfChange.personHistoryRes,
      addressHistoryRes: initialStateSummaryOfChange.addressHistoryRes
    });
  }

}
