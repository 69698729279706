import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { commonUtil } from '@roosevelt/common-ui-lib';

const loginStatus = sessionStorage.getItem('loginStatus');

if (!loginStatus) { sessionStorage.setItem('loginStatus', 'TRYING_EXISTING'); }

export const unflatten = (data) => {
  const result = {};
  for (const i in data) {
    const keys = i.split('.');
    keys.reduce((r, e, j) =>
      r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : [])
      , result);
  }
  return result;
};

export const parseConfigSet = (value: any) => {
  if (typeof (value) === 'string') {
    if (value.startsWith('[]')) {
      return value.replace('[]', '').split(',');
    } else if (value.startsWith('JSON')) {
      const temp = value.replace('JSON', '');
      return JSON.parse(temp.substring(1, temp.length - 1));
    }
  }
  return value;
};

if (environment.production) {
  enableProdMode();
}
const baseUrl = window.location.pathname.split('/')[1];
const nameSpace = window.location.pathname.split('/')[1];
const site = window.location.pathname.split('/')[2];

commonUtil.bootstrap(
  // Overriding the loginRedirectRoute property to support site base ulr for secureAuth.
  fetch(baseUrl !== 'fgp' ? environment.configFile : environment.configFileFgp).then(x => x.json().then(data => unflatten(data)))
    .then((x: { [key: string]: string }) => Object.keys(x)
      .reduce((bag, key) => ({
        ...bag,
        [key]: (parseConfigSet(x[key]))
      }), {})).then((x: any) => {
      const con = {
        ...x,
        secureAuth: {
          ...x.secureAuth,
          loginRedirectRoute: window.location.origin + '/' + ((nameSpace === 'uat40') ? nameSpace + '/' + site : nameSpace) + x.loginRedirectRoute,
        }
      };
      return new Promise(res => res(new Response(JSON.stringify(con))));
    }),
  platformBrowserDynamic,
  AppModule)
  .catch(err => console.error(err));
