import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Lux } from '@roosevelt/common-ui-lib';
import { Observable } from 'rxjs';
import { CreateManageUserAsync } from 'src/app/store/createManageUser/async';
import { getErrors, isUserCreated, isUserGettingCreated } from 'src/app/store/createManageUser/selector';
import { ICreateManageUserState } from 'src/app/store/createManageUser/state';
import { isUserName, matchEmail } from 'src/app/utils/validations/field-rules';
import { getSelectedAdminPlan } from 'src/app/login/store/selectors';
import { ILoginState } from 'src/app/login/store/state';

@Component({
  selector: 'bmt-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {
  luxId = 'BMT-create-user' + this.constructor.name;

  createUserForm: UntypedFormGroup;
  getErrors$: Observable<string> = this.lux.get(getErrors, this.luxId);
  isUserGettingCreated$: Observable<boolean> = this.lux.get(isUserGettingCreated, this.luxId);
  isUserCreated$: Observable<boolean> = this.lux.get(isUserCreated, this.luxId);
  selectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);

  isUserCreationInProcess: boolean;
  createUserErrors: string;
  isUserGotCreated: boolean;
  selectedAdminPlan: string;

  constructor(private router: Router,
              private fb: UntypedFormBuilder,
              private lux: Lux<{ createManageUserState: ICreateManageUserState, login: ILoginState }>,
              private createManageUserAsync: CreateManageUserAsync) {
  }

  ngOnDestroy(): void {
    this.lux.set(state => state.createManageUserState, {errors: '', userCreated: false});
    this.lux.destroy(this.luxId);
  }

  ngOnInit(): void {
    this.selectedAdminPlan$.subscribe(x => this.selectedAdminPlan = x);
    this.getErrors$.subscribe(errors => this.createUserErrors = errors);
    this.isUserGettingCreated$.subscribe(isUserCreationInProcess => this.isUserCreationInProcess = isUserCreationInProcess);
    this.isUserCreated$.subscribe(isCreatedUser => this.isUserGotCreated = isCreatedUser);
    this.setCreateUserForm();
  }

  setCreateUserForm() {
    this.createUserForm = this.fb.group({
      userIdentifier: ['', [isUserName, Validators.required]],
      lastName: ['', [Validators.pattern('^[a-zA-Z.\'-]+( +[a-zA-Z.\'-]+)*$'), Validators.required, Validators.maxLength(50)]],
      firstName: ['', [Validators.pattern('^[a-zA-Z.\'-]+( +[a-zA-Z.\'-]+)*$'), Validators.required, Validators.maxLength(50)]],
      companyName: ['', [Validators.required, Validators.maxLength(150)]],
      emailAddress: ['', [matchEmail('emailAddress', 'confirmEmail'), Validators.maxLength(100), Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$'), Validators.required]],
      confirmEmail: ['', [matchEmail('emailAddress', 'confirmEmail'), Validators.maxLength(100), Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$'), Validators.required]]
    });
  }

  createUser() {
    if (this.createUserForm.valid) {
      delete this.createUserForm.value.confirmEmail;
      const createUserReq = {
        user: {...this.createUserForm.value, adminPayer: this.selectedAdminPlan}
      };
      this.lux.set(state => state.createManageUserState.createUserReq, createUserReq);
      this.createManageUserAsync.createUser().subscribe();
    }
  }

  manageAuthorizations() {
    this.lux.set(state => state.createManageUserState.manageUser, {
      selectedAction: 'authorizations',
      selectedUserId: this.createUserForm.value.userIdentifier,
      selectedUserType: 'User'
    });
    this.router.navigate(['/manage-user']);
  }

}
