import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IClients } from '@roosevelt/client-ui-lib/lib/client-search/store/state';
import { Lux } from '@roosevelt/common-ui-lib';
import { Observable } from 'rxjs';
import { CreateManageUserAsync } from 'src/app/store/createManageUser/async';
import { errorFetchingAuthsToView, errorsFetchingClients, fetchingClients, getAdminAndUserAuthsResposneForView, getAdminClients, getClientsOfAdmin, isFetchingAuthsToView } from 'src/app/store/createManageUser/selector';
import { IAdminAndUserAuthResponse, IAdminClients, ICreateManageUserState, IManageuser } from 'src/app/store/createManageUser/state';

@Component({
  selector: 'bmt-view-auth',
  templateUrl: './view-auth.component.html',
  styleUrls: ['./view-auth.component.scss']
})
export class ViewAuthComponent implements OnInit,OnDestroy {
  luxId = 'BMT-view-auth'+this.constructor.name;
  @Input() userInfo: IManageuser;

  getErrors$:Observable<string> = this.lux.get(errorFetchingAuthsToView, this.luxId);
  isFetchingAuthsToView$:Observable<boolean> = this.lux.get(isFetchingAuthsToView, this.luxId);
  getUserAuthsToview$:Observable<IAdminAndUserAuthResponse> = this.lux.get(getAdminAndUserAuthsResposneForView, this.luxId);
  getAdminClients$:Observable<IAdminClients[]> = this.lux.get(getAdminClients, this.luxId);

  getClientsOfAdmin$:Observable<IClients> = this.lux.get(getClientsOfAdmin, this.luxId);
  errorsFetchingClients$:Observable<string> = this.lux.get(errorsFetchingClients, this.luxId);
  fetchingClients$:Observable<boolean> = this.lux.get(fetchingClients, this.luxId);

  isFetchingUserList: boolean;
  totalRecords:number = 0;
  // currentPage = 1;
  defaultPageSize = 50;
  selectedIndex = 0;
  sortField: string = '';
  sortOrder: string = 'ASC';
  editAuthorizations = false;
  errors: string;
  fetchingAuths: boolean;
  userAndAdminAuths: IAdminAndUserAuthResponse;
  userContext: string[];
  multipleClients: boolean;
  adminClientsInfo: any;
  selectedClient: string = '';
  fetchingClients: boolean;
  errorsFetchingClients: string;

  constructor(private router: Router, private lux: Lux<{createManageUserState: ICreateManageUserState}>, private createManageUserAsync: CreateManageUserAsync) { }
  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

  ngOnInit(): void {
    const adminRole = this.userInfo.adminRole;
    const singleClientOrSubGroupAdmins = ['BMT-GROUP-ADMIN', 'BMT-SUBGROUP-ADMIN', 'BMT-GROUP-DELEGATE', 'BMT-SUBGROUP-DELEGATE'];
    this.userContext = this.userInfo.contextOfAdmin.split('/');
    // (adminRole === 'BMT-CLIENT-ASSOCIATION-ADMIN' || adminRole === 'BMT-AGENT-ADMIN' || adminRole === 'BMT-AGENCY-ADMIN' || adminRole === 'BMT-AGENT-DELEGATE' || adminRole === 'BMT-CLIENT-ASSOCIATION-DELEGATE' || adminRole === 'BMT-AGENT-DELEGATE');
    this.multipleClients = !singleClientOrSubGroupAdmins.includes(adminRole);
    this.errorsFetchingClients$.subscribe(errs => this.errorsFetchingClients = errs);
    this.fetchingClients$.subscribe(fetching => this.fetchingClients = fetching);
    if(this.multipleClients) {
      this.getClients(0);
    };
    // this.getClientsOfAdmin$.subscribe(clientsInfo => {
    //   this.totalRecords = clientsInfo.pagination.totalRecords;
    //   this.adminClientsInfo = clientsInfo.clients;
    // });
  }

  navigateToUserAdmin() {this.router.navigate(['userAdmin/manage'])}

  toggleTheClient(client) {
    this.selectedClient = (this.selectedClient !== client) ? client : '';
  }

  getClients(offset) {
    const req = {
      planAcronym: this.userInfo.planAcronyms,
      offset: offset,
      limit: this.defaultPageSize
    }
    this.createManageUserAsync.getClients(req).subscribe(clientsData => {
      if(clientsData) {
        this.totalRecords = clientsData.pagination ? clientsData.pagination.totalRecords : 0;
        this.adminClientsInfo = clientsData.clients;
        // Move the user to top view
        const element = document.querySelector('#scrollId');
        element.scrollIntoView();
      }
    });
  }

  getMoreClients(page) {
    // this.currentPage = page.page;
    this.getClients(page.pageIndex >= 1 ? (page.pageIndex * this.defaultPageSize) : 0);
  }
}