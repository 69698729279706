import { Inject, NgModule } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { LoginComponent } from './login.component';
import {
  SignInModule,
  Lux,
  InputModule,
  DialogModule,
  ButtonModule,
  SelectModule
} from '@roosevelt/common-ui-lib';
import { ILoginState, loginInitialState } from './store/state';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { initialSolutionState, ISolutionsState } from './store/state';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IAlertsState, initialAlertsState } from '../store/alerts/state';
import { appUiAuthInitialState, IAppAuthorizations } from '../authorizations/state';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    SignInModule,
    ReactiveFormsModule,
    InputModule,
    FormsModule,
    CommonModule,
    DialogModule,
    ButtonModule,
    SelectModule,
    BrowserAnimationsModule
  ]
})
export class LoginModule {
  constructor(@Inject(DOCUMENT) private document: Document, lux: Lux<{ login: ILoginState, solutionsLogin: ISolutionsState, bmtAlertsState: IAlertsState, appUiAuth: IAppAuthorizations }>) {
    const adminPlan = this.document.location.pathname.split('/')[1];
    const loginState = JSON.parse(sessionStorage.getItem(adminPlan + '-login-state'));
    const bmtAlertsState = JSON.parse(sessionStorage.getItem('bmtAlertsState'));
    const appUiAuthState = JSON.parse(sessionStorage.getItem('app-ui-auth'));
    lux.register('login', loginState ? loginState : loginInitialState);
    lux.register('solutionsLogin', initialSolutionState);
    lux.register('bmtAlertsState', bmtAlertsState ? bmtAlertsState : initialAlertsState);
    lux.register('appUiAuth', appUiAuthState ? appUiAuthState : appUiAuthInitialState);
    lux.allChanges().subscribe(state => {
      sessionStorage.setItem(adminPlan + '-login-state', JSON.stringify(state.login));
      sessionStorage.setItem('bmtAlertsState', JSON.stringify(state.bmtAlertsState));
      sessionStorage.setItem('app-ui-auth', JSON.stringify(state.appUiAuth));
    });
  }
}
