import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, InputModule, LoaderModule, DialogModule, RadioModule, CheckboxModule, PaginatorModule, SelectModule, NotificationModule } from '@roosevelt/common-ui-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageUserOrAuthComponent } from './manage-user-or-auth.component';
import { ManageAuthComponent } from './manage-auth/manage-auth.component';
import { ViewAuthComponent } from './view-auth/view-auth.component';
import { ViewAuthAtGroupComponent } from './view-auth/at-group-level/view-auth-at-group-level.component';
import { RouterModule } from '@angular/router';
import { ManageAuthAtGroupComponent } from './manage-auth/at-group-level/at-group-level.component';
import { ManageAuthAtMutiGroupComponent } from './manage-auth/at-multi-group-level/multi-group-level.component';


@NgModule({
  declarations: [ManageAuthComponent, ManageUserOrAuthComponent, ViewAuthComponent, ViewAuthAtGroupComponent, ManageAuthAtGroupComponent, ManageAuthAtMutiGroupComponent],
  imports: [
    CommonModule,
    ButtonModule,
    InputModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    LoaderModule,
    RadioModule,
    CheckboxModule,
    PaginatorModule,
    RouterModule,
    SelectModule,
    NotificationModule
  ],
  exports: [
    ViewAuthAtGroupComponent
  ]
})

export class ManageUserOrAuthModule {
  constructor() {}
}