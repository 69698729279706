import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { AuthorizationsComponent } from './authorizations/authorizations.component';
import { ButtonModule, DialogModule, InputModule, LoaderModule, PaginatorModule } from '@roosevelt/common-ui-lib';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageUserOrAuthModule } from '../manage-user-or-auth/manage-user-or-auth.module';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { RouterModule } from '@angular/router';
import {MultiFactorModule} from '@roosevelt/authentication-lib';

@NgModule({
  declarations: [
    ProfileComponent,
    AuthorizationsComponent,
    UpdatePasswordComponent
  ],
    imports: [
        CommonModule,
        LoaderModule,
        PaginatorModule,
        FormsModule,
        DialogModule,
        ManageUserOrAuthModule,
        RouterModule,
        ReactiveFormsModule,
        InputModule,
        ButtonModule,
        MultiFactorModule
    ]
})
export class ProfileModule {
}
