import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterComponent } from './register/register.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, InputModule, LoaderModule, Lux, SelectModule } from '@roosevelt/common-ui-lib';
import { CredentialsComponent } from './credentials/credentials.component';
import { IRegistrationState, registrationInitialState } from './store/state';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { RouterModule } from '@angular/router';
import { RegistrationComponent } from './registration.component';

@NgModule({
  declarations: [RegisterComponent, RegistrationComponent, CredentialsComponent, ConfirmationComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputModule,
        ButtonModule,
        SelectModule,
        RouterModule,
        LoaderModule
    ]
})
export class RegistrationModule {
  constructor(lux: Lux<{ registration: IRegistrationState }>) {
    const registrationState = JSON.parse(sessionStorage.getItem('registration-state'));
    lux.register('registration', registrationState ? registrationState : registrationInitialState);
    lux.allChanges().subscribe(state => sessionStorage.setItem('registration-state', JSON.stringify(state.registration)));
  }
}
