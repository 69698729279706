import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from './../shared/app.config';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Lux, commonUtil } from '@roosevelt/common-ui-lib';
import { IAuthInfo, ILoginState, ISolutionsState } from './store/state';
import { getContextError, getTimeOutError, getUserInfo, isLoginFailed, noAuthorizations, userHasMoreRoles } from './store/selectors';
import { Observable, from } from 'rxjs';
import { Router } from '@angular/router';
import { IAppAuthorizations, IUiAuthorizations } from '../authorizations/state';
import { getRelaxedUiAuth } from '../authorizations/selector';
import { MagnoliaService } from 'src/app/magnolia/magnolia.service';
import { DOCUMENT } from '@angular/common';
import { OktaAuth } from '@okta/okta-auth-js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  luxId = 'BMT-login' + this.constructor.name;
  @ViewChild('loginForm', { static: false }) loginHtmlForm: ElementRef;
  login: UntypedFormGroup;
  authEndpoint: SafeResourceUrl;
  formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  clientId: string;
  redirectUrl: string;
  pwdResetUrl: string;
  secureAuthEndpoint: string;
  isLoginFailed$: Observable<any> = this.lux.get(isLoginFailed, this.luxId);
  isFailedLogin = false;
  showRegistration = false;
  isFirstTimeLogin = false;
  guard$: Observable<IAuthInfo> = this.lux.get(getUserInfo, this.luxId);
  noAuthorizations$: Observable<boolean> = this.lux.get(noAuthorizations, this.luxId);
  userHasMoreRoles$: Observable<string> = this.lux.get(userHasMoreRoles, this.luxId);
  getTimeOutError$: Observable<string> = this.lux.get(getTimeOutError, this.luxId);
  getContextError$: Observable<string> = this.lux.get(getContextError, this.luxId);
  relaxedAuth$: Observable<IUiAuthorizations | {}> = this.lux.get(getRelaxedUiAuth, this.luxId);
  noAuthorizations = false;
  userHasMoreRoles = '';
  region: string;
  userTypes = [
    { value: null, label: '' },
    { value: 'admin', label: 'Administrator' },
    { value: 'tpa', label: 'Third-party Administrator' },
    { value: 'agency', label: 'Agency' },
    { value: 'agent', label: 'Agent' },
    { value: 'producer', label: 'Producer' }
  ];
  timeOutError: string;
  contextError: string;
  isLoggedIn: boolean;
  renPlan: boolean;
  routesMapping = {
    manageeligibility: '/eligibility/member',
    benefitsInquiry: '/benefits',
    reports: '/reports',
    billing: '/billing',
    summaryOfChanges: '/summary-of-changes',
    dentistDirectory: '/dentist',
    sat: '/sat',
    userAdmin: '/userAdmin',
    profile: '/profile/authorizations'
  };
  loginPageContent: any;
  isUsingSecureAuth: boolean;

  constructor(
    @Inject(DOCUMENT) private docRef: Document,
    private magnoliaService: MagnoliaService,
    private sanitizer: DomSanitizer,
    private lux: Lux<{ login: ILoginState, solutionsLogin: ISolutionsState, appUiAuth: IAppAuthorizations }>,
    private env: AppConfig, private router: Router,
    private http: HttpClient,
    private oktaAuth: OktaAuth) {
    this.login = this.formBuilder.group({
      password: new UntypedFormControl('', Validators.required),
      userId: new UntypedFormControl('', Validators.required)
    });
    this.clientId = env.clientId;
    this.redirectUrl = env.loginRedirectRoute;
    this.secureAuthEndpoint = env.authEndpoint;
    this.region = env.region;
    this.renPlan = docRef.location.pathname.split('/')[1].toLowerCase() === 'ren';
    this.pwdResetUrl = this.renPlan ? env.renPasswordResetUrl : env.passwordResetUrl;
  }
  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

  ngOnInit() {
    this.isUsingSecureAuth = this.env.secureAuth.use;
    (this.docRef.querySelector('#click-to-share') as HTMLElement).style.display = 'block';

    // if(this.env.baseURLsAllowed.indexOf(window.location.pathname.split('/')[1]) < 0) {
    //   this.router.navigate(['/404']);
    // };
    this.isLoginFailed$.subscribe(x => this.isFailedLogin = x);
    this.noAuthorizations$.subscribe(x => this.noAuthorizations = x);
    this.userHasMoreRoles$.subscribe(hasMoreRoles => this.userHasMoreRoles = hasMoreRoles);
    this.getTimeOutError$.subscribe(timeoutErr => this.timeOutError = timeoutErr);
    this.getContextError$.subscribe(err => this.contextError = err);
    this.magnoliaService.getMagnoliaContent('login').subscribe((content) => {
      this.loginPageContent = this.sanitizer.bypassSecurityTrustHtml(content);
    });

    from(commonUtil.authentication.status(this.oktaAuth, this.http, this.env)).subscribe(status => {
      console.log(status);
      this.router.navigateByUrl('/oidc-callback');

      this.guard$.subscribe(info => {
        this.isLoggedIn = (info && (new Date().valueOf() / 1000) < info.expirationTime);
        if (!this.isLoggedIn) {
          if (!sessionStorage.getItem('nonce')) {
            sessionStorage.setItem('nonce', this.generateNonce());
          }
          this.authEndpoint = this.buildUrl();
        } else {
          this.relaxedAuth$.subscribe(uiAuth => {
            if (uiAuth && Object.keys(uiAuth).length) {
              const leftNavFeatures = ['profile', ...(uiAuth as IUiAuthorizations).leftnav];
              const isEligInNavigation = leftNavFeatures && leftNavFeatures.length && leftNavFeatures.includes('manageeligibility');
              this.router.navigateByUrl(isEligInNavigation ? '/eligibility/member' : this.routesMapping[leftNavFeatures[0]]);
            }
          }).unsubscribe();
        }
      });
    });
  }


  buildUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.secureAuthEndpoint + '?' + new HttpParams({
        fromObject:
          {
            client_id: this.clientId,
            redirect_uri: this.redirectUrl,
            scope: 'openid+profile+email+address+phone',
            response_type: 'id_token',
            nonce: sessionStorage.getItem('nonce')
          }
      }
    ).toString());
  }

  public generateNonce(): string {
    const randomString = (length) => {
      let text = '';
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };
    return randomString(32);
  }

  submitForm(form: UntypedFormGroup) {
    if (form.valid) {
      this.loginHtmlForm.nativeElement.userId.value = this.loginHtmlForm.nativeElement.userId.value.trim();
      this.loginHtmlForm.nativeElement.submit();
    } else {
      window.scrollTo(0, 0);
    }
  }

  forgotPassword() {
    sessionStorage.setItem('baseUrl', this.docRef.location.origin + '/' + this.docRef.location.pathname.split('/')[1].toLowerCase());
    this.docRef.location.href = this.pwdResetUrl;
  }

  firstTimeLogin() {
    this.isFirstTimeLogin = true;
  }

}
