import { Component, OnDestroy, OnInit } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib';
import { Observable } from 'rxjs';
import { getSelectedAdminPlan } from '../login/store/selectors';
import { ILoginState } from '../login/store/state';
import { IAppAuthorizations, IUiAuthorizations } from 'src/app/authorizations/state';
import { getRelaxedUiAuth } from 'src/app/authorizations/selector';
import { BenefitsUiAuthSerive } from '@roosevelt/benefits-ui-lib';
import { AuthorizationsAsync } from 'src/app/authorizations/authorizations-async';
import * as moment from 'moment';

@Component({
  selector: 'app-benefits-inquiry',
  templateUrl: './benefits-inquiry.component.html',
  styleUrls: ['./benefits-inquiry.component.scss']
})
export class BenefitsInquiryComponent implements OnInit, OnDestroy {
  luxId = 'BMT-BenefitsInquiry-Comp' + this.constructor.name;
  selectedAdminPlan = '';
  selectedSubClients: any;

  feed = {
    planAcronym: '',
    clientSpecifiedId: '',
    subClientSpecifiedId: '',
    asOf: ''
  };

  constructor(
    private lux: Lux<{ login: ILoginState, appUiAuth: IAppAuthorizations }>,
    private uiAuthAsync: AuthorizationsAsync,
    private benefitsUiAuth: BenefitsUiAuthSerive
  ) {
  }

  selectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);
  relaxedAuth$: Observable<IUiAuthorizations | {}> = this.lux.get(getRelaxedUiAuth, this.luxId);

  ngOnInit() {
    this.relaxedAuth$.subscribe((uiAuth: IUiAuthorizations) => {
      if (uiAuth.benefitsInquiry) {
        this.benefitsUiAuth.setRelaxedAuthorizations(uiAuth.benefitsInquiry);
      }
    });
    setTimeout(() => {
      this.resetOrClientSelected(event);
    }, 0);
    this.selectedAdminPlan$.subscribe(x => this.selectedAdminPlan = x);
  }

  resetOrClientSelected(event) {
    this.selectedSubClients = [];
  }

  getSubClientDetails(clientInfo) {
    if (clientInfo && clientInfo.length) {
      const appContext = `${this.selectedAdminPlan}/${clientInfo[0].planAcronym}/${clientInfo[0].client.specifiedId}/${clientInfo[0].specifiedId}`;
      this.uiAuthAsync.getAuthorizations('strict', appContext).subscribe(uiAuth => {
        this.selectedSubClients = clientInfo;
        this.feed = {
          planAcronym: clientInfo[0].planAcronym,
          clientSpecifiedId: clientInfo[0].client.specifiedId,
          subClientSpecifiedId: clientInfo[0].specifiedId,
          asOf: moment(clientInfo[0].asOfDate).format('YYYY-MM-DD')
        };
        if (uiAuth.benefitsInquiry) {
          this.benefitsUiAuth.setStrictAuthorizations(uiAuth.benefitsInquiry);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }
}
