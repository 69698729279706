import {
  IAddressHistory,
  ICOBHistory,
  ICoverageHistory,
  IEligHistory,
  IPersonHistory,
  ISummaryOfChangesState,
  ITransactions,
  ITransactionsRes
} from './state';

export const getTSessionReq = (state: { sChanges: ISummaryOfChangesState }): any => {
  return {
    pagination: {
      offset: state.sChanges.transactionsReq.pagination.offset,
      limit: 100
    },
    searchCriteria: {
      sessionId: sessionStorage.getItem('sessionId')
    }
  };
};


export const getTSearchReq = (state: { sChanges: ISummaryOfChangesState }): any => {
  return {
    pagination: {
      offset: state.sChanges.transactionsReq.pagination.offset,
      limit: 100
    },
    searchCriteria: state.sChanges.transactionsReq.searchCriteria
  };
};

export const isFetchingUserTransactions = (state: { sChanges: ISummaryOfChangesState }): boolean => state.sChanges.isFetchingTransactions || state.sChanges.isFetchingDetails;

export const getTSessionResponse = (state: { sChanges: ISummaryOfChangesState }): ITransactionsRes => state.sChanges.sessionTransactionsRes;

export const getTSearchResponse = (state: { sChanges: ISummaryOfChangesState }): ITransactionsRes => state.sChanges.searchTransactionsRes;

export const getSelectedTransaction = (state: { sChanges: ISummaryOfChangesState }): ITransactions => state.sChanges.selectedTransaction;

export const getMemberEligResponse = (state: { sChanges: ISummaryOfChangesState }): IEligHistory[] => state.sChanges.memberEligHistoryRes;

export const getCobSearchResponse = (state: { sChanges: ISummaryOfChangesState }): ICOBHistory[] => state.sChanges.cobHistoryRes;

export const getCoverageResponse = (state: { sChanges: ISummaryOfChangesState }): ICoverageHistory[] => state.sChanges.coverageHistoryRes;

export const getPersonHistoryResponse = (state: { sChanges: ISummaryOfChangesState }): IPersonHistory[] => state.sChanges.personHistoryRes;

export const getAddressHistoryResponse = (state: { sChanges: ISummaryOfChangesState }): IAddressHistory[] => state.sChanges.addressHistoryRes;


export const getMemberEligSearchReq = (state: { sChanges: ISummaryOfChangesState }): any => {
  return {
    searchCriteria: {
      memberId: state.sChanges.selectedTransaction.personId,
      subscriberEnrollmentId: state.sChanges.selectedTransaction.subscriberEnrollmentId,
      clientSpecifiedIdentifier: state.sChanges.selectedTransaction.groupId,
      subclientSpecifiedIdentifier: state.sChanges.selectedTransaction.subgroupId,
      planAcronyms: [ state.sChanges.selectedTransaction.payer ],
      historyType: 'transactional'
    }
  };
};

export const getCobSearchReq = (state: { sChanges: ISummaryOfChangesState }): any => {
  return {
    searchCriteria: {
      memberId: state.sChanges.selectedTransaction.personId,
      subscriberEnrollmentId: state.sChanges.selectedTransaction.subscriberEnrollmentId,
      historyType: 'transactional'
    }
  };
};

export const getCoverageSearchReq = (state: { sChanges: ISummaryOfChangesState }): any => {
  return {
    searchCriteria: {
      memberId: state.sChanges.selectedTransaction.personId,
      subscriberEnrollmentId: state.sChanges.selectedTransaction.subscriberEnrollmentId,
      planAcronyms: [ state.sChanges.selectedTransaction.payer ],
      historyType: 'transactional',
    }
  };
};

export const getPersonAndAddressSearchReq = (state: { sChanges: ISummaryOfChangesState }): any => {
  return {
    searchCriteria: {
      payer: state.sChanges.selectedTransaction.payer,
      groupSpecifiedIdentifier: state.sChanges.selectedTransaction.groupId,
      historyType: 'transactional',
    }
  };
};
