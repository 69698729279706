import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Lux } from '@roosevelt/common-ui-lib';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICustomGroupReportRes, IReportsState } from 'src/app/store/reports/state';
import { combineLatest, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';


@Component({
  templateUrl: './custom-group-report.component.html',
  styleUrls: ['./custom-group-report.component.scss']
})
export class CustomGroupReportComponent implements OnInit {

  @Output() getCustomGroupReport = new EventEmitter<any>();
  @Output() selectedReport = new EventEmitter<any>();
  @Output() resetReport = new EventEmitter<any>();
  customGroupError: string;
  customGroupForm: UntypedFormGroup;
  customGroupForm$: Subscription;
  customGroupReports: ICustomGroupReportRes;
  defaultPageSize = 25;
  selectedAdminPlan: string;
  selectedSubClients: any;
  selectedClient: any;
  isFetching: boolean;
  showResults: boolean;
  allSubClientFlow = false;
  frequencyDropDown = [{ label: 'All', value: 'all' }, { label: 'Annually', value: 'Annually' }, { label: 'Quarterly', value: 'Quarterly' }, { label: 'Monthly', value: 'Monthly' }];

  constructor(private lux: Lux<{ reportsState: IReportsState }>, private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.setCustomGroupForm();
  }

  setCustomGroupForm() {
    this.customGroupForm = this.fb.group({
      fromDate: [moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD'), Validators.required],
      toDate: [moment(new Date()).format('YYYY-MM-DD')],
      frequency: ['', Validators.required],
    });
    this.customGroupForm$ = combineLatest([
      this.customGroupForm.controls.fromDate.valueChanges.pipe(startWith('')),
      this.customGroupForm.controls.toDate.valueChanges.pipe(startWith('')),
      this.customGroupForm.controls.frequency.valueChanges.pipe(startWith('')),
    ]).subscribe(x => {
      this.resetReport.emit(x);
    });
  }

  resetClientSelected(event) {
    this.selectedSubClients = [];
    this.resetReport.emit(event);
    this.resetCustomGroupForm();
  }

  clientSelected(event) {
    this.selectedClient = event;
  }

  subClientSelected(clientInfo) {
    this.allSubClientFlow = !Array.isArray(clientInfo);
    this.selectedSubClients = clientInfo;
    this.resetReport.emit();
    this.resetCustomGroupForm();
  }

  resetCustomGroupForm() {
    this.customGroupForm.reset({
      fromDate: moment(new Date()).subtract(1, 'year').format('YYYY-MM-DD'),
      toDate: moment(new Date()).format('YYYY-MM-DD'),
      frequency: '',
    });
  }

  onCustomGroupSubmitted(pageIndex) {
    this.showResults = true;
    this.getCustomGroupReport.emit(pageIndex);
  }

  pageChanged(page) {
    this.onCustomGroupSubmitted(page.pageIndex >= 1 ? (page.pageIndex * this.defaultPageSize) : 0);
  }

}
