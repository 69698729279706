import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ach-payer-address',
  templateUrl: 'ach-payer-address.component.html',
  styleUrls: ['ach-payer-address.component.scss'],
})
export class AchPayerAddressComponent implements OnInit {
  @Input() payer;
  constructor() {}

  ngOnInit() {
  }
}
