import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib';
import { Observable } from 'rxjs';
import { ClientKnowledgeAsync } from '../store/clientKnowledge/async';
import { isFetching, getError } from '../store/clientKnowledge/selector';
import { IClientKnowledgeState } from '../store/clientKnowledge/state';
import { ClientknowledgeComponent } from './clientknowledge.component';

@Component({
  selector: 'app-client-knowledge',
  template: '<ng-container #ClientKnowledgeComponent></ng-container>'
})

export class ClientKnowledgeContainer implements OnInit, OnDestroy {

  luxId = 'BMT-ClientKnowledge-Comp'+this.constructor.name;
  @ViewChild('ClientKnowledgeComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: ClientknowledgeComponent;
  error$: Observable<string> = this.lux.get(getError, this.luxId);
  isFetching$: Observable<string> = this.lux.get(isFetching, this.luxId);
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
    , private async: ClientKnowledgeAsync
    , private lux: Lux<{ clientKnowledgeState: IClientKnowledgeState }>) {
  }

  ngOnInit(): void {
    this._loadComponent();
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      ClientknowledgeComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;

    this.error$.subscribe(error => {
      this.componentInstance.error = error;
    });

    this.isFetching$.subscribe(fetching => {
      this.componentInstance.isFetching = fetching;
    });
    this.componentInstance.openClientKnowledge.subscribe(this.openClientKnowledge.bind(this));
  }

  ngOnDestroy() {
    this.lux.destroy(this.luxId);
  }

  openClientKnowledge() {
    this.async.getClientKnowledgeURLKey()
    .subscribe(response => {
      if (this.componentInstance.error === '') {
        window.open(response, 'Client Knowledge Reports', 'height=' + screen.height + ',width=' + screen.width);
      }
    });
  }
}
