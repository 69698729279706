import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';

@Injectable({
  providedIn: 'root'
})
export class ClientKnowledgeService {

  constructor(private http: HttpClient, private config: AppConfig) { }

  getClientKnowledgeURLKey() {
    return this.http.get(this.config.clientKnowledgeUrl, {observe: 'response', responseType: 'text'});
  }
}
