// tslint:disable: max-line-length
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IClientPlanDetails, IContractResponse, initialMassActionState } from '../store/massActions/state';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { matchSsns } from '../utils/validations/field-rules';
import { Subscription } from 'rxjs';
import { AppConfig } from '../shared/app.config';
import { IUiAuthorizations } from 'src/app/authorizations/state';
import { AuthorizationsAsync } from 'src/app/authorizations/authorizations-async';
import * as moment from 'moment';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  templateUrl: './manage-eligibility.component.html',
  styleUrls: ['./manage-eligibility.component.scss']
})
export class ManageEligibilityComponent implements OnInit, OnDestroy {
  region = this.config.region;
  navgationLinks = [
    {
      title: 'Member',
      route: 'member'
    },
    {
      title: 'Add Member',
      route: 'add-member'
    },
    {
      title: 'Family Enrollment',
      route: 'family-enrollment'
    }
  ];
  showDestinationClient: boolean;
  selectedSubClients: any;
  sourceSelectedSubClient: any;
  subHeader: string;
  isDisplayDropdown: boolean;
  @Output() setMassActionStore: EventEmitter<any> = new EventEmitter();
  @Output() setMemberStore: EventEmitter<any> = new EventEmitter();
  resetButton: HTMLElement;
  actionChoosed: any;
  showCopyError: boolean;
  addMemberForm: UntypedFormGroup;
  setDefaultsForm: UntypedFormGroup;
  changeType = 'text';
  routeSub: Subscription;
  selectedAdminPlan = '';
  findMemberFeed: any;
  manageEligFeatures = [];
  displayAddMemberFrom: boolean;
  contractResposnse: IClientPlanDetails;
  showSsnFormForFamilyEnroll: boolean;
  receivedDate = moment(new Date()).format('MM/DD/YYYY');
  isFetchingUiAuth: boolean;
  disableSetDefaults: boolean;
  isFecthingContractInfo: boolean;
  eligStatusReasons = [];
  qualifierTypes = [];
  memberBenefitTypes = [];
  displayFamilyEnrollDefaultsForm: boolean;
  constructor(
    private route: Router,
    private fb: UntypedFormBuilder,
    private activeRoute: ActivatedRoute,
    private config: AppConfig,
    private uiAuthAsync: AuthorizationsAsync) {
  }

  ngOnInit() {
    this.subHeader = 'Member';

    this.routeSub = this.activeRoute.params.subscribe(params => {
      // route param
      this.subHeader = this.navgationLinks.filter(route => route.route === params.id)[0].title
    });
  }

  showHideDropdown() {
    this.isDisplayDropdown = !this.isDisplayDropdown;
  }

  resetSearch() {
    this.resetButton = document.querySelector('.client-search-buttons .rs-button');
    if (this.resetButton && this.resetButton != null) {
      this.resetButton.click();
    }
    this.addMemberForm && this.addMemberForm.reset();
    this.findMemberFeed = undefined;
  }

  setPageFlow(page) {
    this.subHeader = page;
    this.selectedSubClients = [];
    this.resetSearch();
  }


  clientSelected(event) {
    this.selectedSubClients = [];
  }

  subClientSelected(event) {
    let stateObj = {}
    this.selectedSubClients = event;
    if (this.selectedSubClients.length) {
      const appContext = `${this.selectedAdminPlan}/${event[0].client.planAcronym}/${event[0].client.specifiedId}/${event[0].specifiedId}`;
      this.callStrictAuthoriztions(appContext, 'addMember');
      stateObj = {
        sourceClient: this.selectedSubClients[0].client,
        sourceSubClients: this.selectedSubClients
      };
    }
    this.setMassActionStore.emit(stateObj);
    this.addMemberForm && this.addMemberForm.reset();
    this.setDefaultsForm && this.setDefaultsForm.reset();
  }

  goToMemberDetails(event) {
    const appContext = `${this.selectedAdminPlan}/${event.planAcronym}/${event.clientSpecifiedIdentifier}/${event.subclientSpecifiedIdentifier}`;
    const stateObj = {
      selectedSubscriber: event
    };
    this.setMemberStore.emit(stateObj);
    this.callStrictAuthoriztions(appContext, 'member-details');
  }

  callStrictAuthoriztions(appContext, flow?) {
    this.uiAuthAsync.getAuthorizations('strict', appContext).subscribe((uiAuth: IUiAuthorizations) => {
      if(Object.keys(uiAuth).length && uiAuth.manageeligibility) {
        // Navigate to member details page
        if(flow === 'member-details') { this.route.navigateByUrl('/details') };
        // If the user picks add member or family enrollment flows
        if(this.subHeader === 'Add Member' || this.subHeader === 'Family Enrollment') {
          this.displayAddMemberFrom = Object.keys(uiAuth).length && uiAuth.manageeligibility && uiAuth.manageeligibility.includes(flow);
          this.setConfirmSsnForm();
        }
      }
    });
  }

  clientSearchReset(event) {
    this.selectedSubClients = [];
    this.displayAddMemberFrom = false;
    this.displayFamilyEnrollDefaultsForm = false;
  }

  setConfirmSsnForm() {
    this.addMemberForm = this.fb.group({
      ssn: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(9), Validators.maxLength(9), Validators.required]],
      confirmSsn: ['', [Validators.pattern('^[0-9]*$'), matchSsns('ssn', 'confirmSsn'), Validators.minLength(9), Validators.maxLength(9), Validators.required]]
    });
    this.addMemberForm.valueChanges.subscribe(formVal => {
      this.changeType = formVal.ssn && formVal.ssn.length >= 9 ? 'password' : 'text';
    });
  }

  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.key === '67') {
      $event.preventDefault();
      this.showCopyError = true;
      setTimeout(() => {
        this.showCopyError = false;
      }, 5000);
    }
  }

  navigateToAddMember() {
    this.findMemberFeed = {
      planAcronym: this.selectedSubClients[0].planAcronym,
      clientId: this.selectedSubClients[0].client.specifiedId,
      subClientId: this.selectedSubClients[0].specifiedId,
      memberId: this.addMemberForm.value.confirmSsn,
      adminPlan: this.selectedAdminPlan
    };
  }

  foundMember(event) {
    if (event === 'cancel') {
      this.resetSearch();
    } else {
      this.setAddMemberFeed(event);
    }
  }

  setAddMemberFeed(event) {
    this.setMemberStore.emit({
      addMemberFeed: {
        planAcronym: this.selectedSubClients[0].planAcronym,
        clientId: this.selectedSubClients[0].client.specifiedId,
        subClientId: this.selectedSubClients[0].specifiedId,
        asOf: this.selectedSubClients[0].asOfDate,
        memberId: this.addMemberForm.value.confirmSsn,
        subscriberEnrollmentID: '',
        ...(this.subHeader === 'Family Enrollment') && {
          eligibilityEffectiveDate: this.setDefaultsForm.value.eligibilityEffectiveDate,
          receivedDate: this.setDefaultsForm.value.receivedDate,
          qualifierType: this.setDefaultsForm.value.qualifierType,
          memberBenefitType: this.setDefaultsForm.value.memberBenefitType,
          eligibilityStatusReason: this.setDefaultsForm.value.eligibilityStatusReason,
          eligibilityStatus: this.setDefaultsForm.value.eligibilityStatus
        }
      },
      event: event,
      ...(typeof event !== "string") && {
        selectedSubscriber: {
          firstName: event.name.first,
          lastName: event.name.last
        }
      }
    });
  }

  setFamilyEnrollDefaultsForm() {
    this.setDefaultsForm = this.fb.group({
      receivedDate: {value: moment(new Date()).format('YYYY-MM-DD'), disabled: true},
      eligibilityEffectiveDate: ['', Validators.required],
      eligibilityStatus: ['active', Validators.required],
      eligibilityStatusReason: [this.eligStatusReasons.find(reason => reason.value === 'New') ? 'New' : '', Validators.required],
      qualifierType: '',
      memberBenefitType: ''
    });

    this.setDefaultsForm.valueChanges.pipe(distinctUntilChanged()).subscribe(formVal => {
      if(this.setDefaultsForm.valid && this.disableSetDefaults) {
        this.showSsnFormForFamilyEnroll = this.disableSetDefaults = false;
      }
      if(!this.setDefaultsForm.valid) {
        this.showSsnFormForFamilyEnroll = false;
      }
    })
  }

  setDefaultsForFamilyEnroll() {
    this.showSsnFormForFamilyEnroll = this.disableSetDefaults = true;
    this.setConfirmSsnForm();
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.resetSearch();
    }, 0);
  }
}
