export const initialMemberState: IMemberState = {
  selectedSubscriber: {},
  addMemberFeed: {
    planAcronym: '',
    clientId: '',
    subClientId: '',
    asOf: ''
  },
  selectedDependent: {},
  dependentAdded: false
};
export interface IMemberState {
  selectedSubscriber: {};
  addMemberFeed: {
    subscriberName?: string;
    planAcronym: string | string [];
    clientId: string;
    subClientId: string;
    memberId?: number;
    subscriberEnrollmentID?: number;
    asOf: string;
    eligibilityEffectiveDate?: any;
    receivedDate?: any;
    qualifierType?: any;
    memberBenefitType?: any;
    eligibilityStatusReason?: string;
    eligibilityStatus?: string;
  };
  selectedDependent: {};
  dependentAdded: boolean;
}
