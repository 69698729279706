import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder, private route: Router,
              private activatedRoute: ActivatedRoute) {
  }

  registerForm: UntypedFormGroup;
  groupAssocLevel: boolean;
  groupLevel: boolean;
  subgroupLevel: boolean;
  agencyLevel: boolean;
  independentAgent: boolean;
  error: string;
  @Output() registerSubmit: EventEmitter<any> = new EventEmitter();


  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.groupAssocLevel = params.lvl === 'groupAssocLevel';
      this.groupLevel = params.lvl === 'groupLevel';
      this.subgroupLevel = params.lvl === 'subgroupLevel';
      this.agencyLevel = params.lvl === 'agencyLevel';
      this.independentAgent = params.lvl === 'independentAgent';
    });
    if (!(this.groupAssocLevel || this.groupLevel || this.subgroupLevel || this.agencyLevel || this.independentAgent)) {
      this.error = 'Please use the link provided in your registration email. Please try again or retype the webpage address.';
    }
    this.registerForm = this.fb.group({
      authorizationCode: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.maxLength(100), Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]],
      groupSpecifiedIdentifier: [{value: '', disabled: this.agencyLevel || this.independentAgent}, Validators.required],
      subGroupSpecifiedIdentifier: [{value: '', disabled: !this.subgroupLevel}, Validators.required],
      agencyTin: [{value: '', disabled: !this.agencyLevel}, [Validators.required, Validators.minLength(9)]],
      agentSsn: [{value: '', disabled: !this.independentAgent}, [Validators.required, Validators.minLength(9)]]
    });
  }

}
