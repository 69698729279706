import { Injectable } from '@angular/core';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { ReportsApiService } from './api.service';
import { of } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib';
import { IReportsState } from './state';
import { HttpErrorResponse } from '@angular/common/http';
import { getCustomGroupReportReq, getMassActionsReport, getODNoticeReq } from './selector';
import { initialReportsState } from 'src/app/store/reports/initialState';

@Injectable({
  providedIn: 'root'
})
export class ReportsAsync {
  constructor(
    private lux: Lux<{ reportsState: IReportsState }>,
    private reportsService: ReportsApiService,
  ) {
  }

  // Mass Actions Report
  massActionsReport() {
    return this.lux.get(getMassActionsReport).pipe(
      first(),
      tap(() =>
        this.lux.set(x => x.reportsState, { massReportError: 'success' })),
      mergeMap(x => this.reportsService.massActionsReport()),
      tap(results => {
        this.lux.set(x => x.reportsState, {
          massActionsReport: results,
          massReportError: ''
        });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map(item => item.massReportError).join('\n');
        }
        this.lux.set(x => x.reportsState, { massReportError: errorMessage });
        return of(errorMessage);
      })
    );
  }

  // overage Dependent Notice
  getODNotice() {
    return this.lux.get(getODNoticeReq).pipe(
      first(),
      tap(() => this.lux.set(x => x.reportsState, { isFetchingOverageDepNotice: true, overageDepNoticeError: '' })),
      mergeMap(x => this.reportsService.getODNotice(x)),
      tap(results => {
        this.lux.set(x => x.reportsState, { overageDepNoticeRes: results, isFetchingOverageDepNotice: false });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map(item => item.errorMessage).join('\n');
        }
        this.lux.set(x => x.reportsState, { overageDepNoticeError: errorMessage, isFetchingOverageDepNotice: false });
        return of(errorMessage);
      })
    );
  }

  // get pdf Document
  getPDFDocument(req) {
    return of(req).pipe(
      first(),
      tap(() => this.lux.set(x => x.reportsState, { isFetchingDocument: true, errorFetchingDocument: '' })),
      mergeMap(x => this.reportsService.getDocument(x)),
      tap(res => {
        this.lux.set(x => x.reportsState, { isFetchingDocument: false });
        const file = new Blob([res], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }),
      catchError((e: HttpErrorResponse) => {
        let err = bufferToString(e.error);
        if (err && err.apiErrorList && err.apiErrorList.length > 0) {
          err = err.apiErrorList.map(item => item.errorMessage).join('\n');
        }
        this.lux.set(x => x.reportsState, { errorFetchingDocument: err, isFetchingDocument: false });
        return of(err);
      })
    );
  }

  // Adhoc Report for the Overage Dependent
  getAdhocReport(req) {
    return of(req).pipe(
      first(),
      tap(() => this.lux.set(x => x.reportsState, { isFetchingAdhocReport: true, adhocReportError: '' })),
      mergeMap(x => this.reportsService.getAdhocReport(x)),
      tap(() => {
        this.lux.set(x => x.reportsState, { isFetchingAdhocReport: false });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map(item => item.errorMessage).join('\n');
        }
        this.lux.set(x => x.reportsState, { adhocReportError: errorMessage, isFetchingAdhocReport: false });
        return of(errorMessage);
      })
    );
  }

  // Consolidated Report for the Overage Dependent
  getConsolidatedReport(req) {
    return of(req).pipe(
      first(),
      tap(() => this.lux.set(x => x.reportsState, { isFetchingConsolidatedReport: true, consolidatedReportErrors: '' })),
      mergeMap(x => this.reportsService.getConsolidatedReport(x)),
      tap(() => {
        this.lux.set(x => x.reportsState, { isFetchingConsolidatedReport: false });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map(item => item.errorMessage).join('\n');
        }
        this.lux.set(x => x.reportsState, { consolidatedReportErrors: errorMessage, isFetchingConsolidatedReport: false });
        return of(errorMessage);
      })
    );
  }

  // custom group reports
  getCustomGroupReports() {
    return this.lux.get(getCustomGroupReportReq).pipe(
      first(),
      tap(() => this.lux.set(x => x.reportsState, { isFetchingCustomGroupReport: true, customGroupReportError: '', errorFetchingDocument: '', customGroupReportRes: initialReportsState.customGroupReportRes })),
      mergeMap(x => this.reportsService.getCustomGroupReport(x)),
      tap(results => {
        this.lux.set(x => x.reportsState, { customGroupReportRes: results, isFetchingCustomGroupReport: false });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map(item => item.errorMessage).join('\n');
        }
        this.lux.set(x => x.reportsState, { customGroupReportError: errorMessage, isFetchingCustomGroupReport: false });
        return of(errorMessage);
      })
    );
  }

  // get excel Document
  getExcelDocument(req) {
    return of(req).pipe(
      first(),
      tap(() => this.lux.set(x => x.reportsState, { isFetchingDocument: true, errorFetchingDocument: '' })),
      mergeMap(x => this.reportsService.getDocument(x)),
      tap(res => {
        this.lux.set(x => x.reportsState, { isFetchingDocument: false });
        const file = new Blob([res], { type: 'application/vnd.ms-excel' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }),
      catchError((e: HttpErrorResponse) => {
        let err = bufferToString(e.error);
        if (err && err.apiErrorList && err.apiErrorList.length > 0) {
          err = err.apiErrorList.map(item => item.errorMessage).join('\n');
        }
        this.lux.set(x => x.reportsState, { errorFetchingDocument: err, isFetchingDocument: false });
        return of(err);
      })
    );
  }
}

export function bufferToString(buf) {
  return JSON.parse(String.fromCharCode.apply(null, new Uint8Array(buf)));
}
