import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AddMemberComponent } from './add-member.component';
import { Lux } from '@roosevelt/common-ui-lib';
import { IMassActionState } from '../store/massActions/state';
import { IMemberState } from '../store/member/state';
import { Observable, Subscription } from 'rxjs';
import { getAddMemberFeed } from '../store/member/selector';
import { getSelectedAdminPlan } from '../login/store/selectors';
import { ILoginState } from '../login/store/state';
import { IAppAuthorizations, IUiAuthorizations } from 'src/app/authorizations/state';
import { getStrictUiAuth } from 'src/app/authorizations/selector';
import { ClientUiAuthSerive } from '@roosevelt/client-ui-lib';
import { EligUiAuthSerive } from '@roosevelt/elig-ui-lib';

@Component({
  selector: 'app-add-member',
  template: '<ng-container #addMemberComponent></ng-container>'
})
export class AddMemberContainer implements OnInit, OnDestroy {
  luxId = 'BMT-AddMember-component' + this.constructor.name;
  getAddMemberFeed$: Observable < any > = this.lux.get(getAddMemberFeed, this.luxId);
  @ViewChild('addMemberComponent', {
    read: ViewContainerRef,
    static: true
  })
  componentArea: ViewContainerRef;
  componentInstance: AddMemberComponent;
  memberStoreSub: Subscription;
  selectedAdminPlan$: Observable < string > = this.lux.get(getSelectedAdminPlan, this.luxId);
  strictAuth$: Observable < IUiAuthorizations | {} > = this.lux.get(getStrictUiAuth, this.luxId);

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private clientUiAuth: ClientUiAuthSerive,
    private eligUiAuth: EligUiAuthSerive,
    private lux: Lux < {
      massAction: IMassActionState,
      member: IMemberState,
      login: ILoginState,
      appUiAuth: IAppAuthorizations
    } > ) {}

  ngOnInit() {
    this._loadComponent();
    this.getAddMemberFeed$.subscribe(x => {
      this.componentInstance.clientSelected = {
        planAcronym: x.planAcronym,
        clientSpecifiedId: x.clientId,
        subClientSpecifiedId: x.subClientId,
        asOf: x.asOf
      };
      this.componentInstance.planDetailsFeed = {
        subscriberName: x.subscriberName,
        planAcronym: x.planAcronym,
        clientId: x.clientId,
        subClientId: x.subClientId
      };
      this.componentInstance.addMemberFeed = {
        clientId: x.clientId,
        subClientId: x.subClientId,
        planAcronym: x.planAcronym,
        memberId: x.memberId,
        subscriberEnrollmentID: x.subscriberEnrollmentID,
        asOf: x.asOf
      };
    });
    this.memberStoreSub = this.componentInstance.setMemberStore.subscribe(x => {
      this.lux.set(state => state.member, {
        ...x
      });
    });
    this.selectedAdminPlan$.subscribe(x => this.componentInstance.selectedAdminPlan = x);
    this.strictAuth$.subscribe((uiAuth: IUiAuthorizations) => {
      this.eligUiAuth.setStrictAuthorizations(uiAuth.eligibility);
      this.clientUiAuth.setStrictAuthorizations(uiAuth.client);
    });
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      AddMemberComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;
  }

  ngOnDestroy(): void {}
}
