import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import {AppConfig} from '../shared/app.config';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  page = 'profile';
  routeSub: Subscription;
  enableMfa: boolean;
  isImpersonating: boolean;

  constructor(private activeRoute: ActivatedRoute, private config: AppConfig) {
  }

  ngOnInit(): void {
    this.isImpersonating = !!sessionStorage.getItem('impersonate_user');
    this.enableMfa = this.config.enableMfa;
    this.routeSub = this.activeRoute.params.subscribe(params => this.page = params.id);
  }

  getMfaLabels() {
    return {
      removeAllBtn: '',
      description: 'Multi-factor authentication, commonly known as MFA or 2-factor authentication, is a security measure that can be added to keep your account secure. It requires setting up a second device where you will receive a code to verify that you are the true account holder prior to logging in. The device you set up below for MFA will be solely for your use logging into the portal and will not impact your personal contact information on file.',
      title: 'Multi-factor Management',
      catalogError: 'Unable to fetch the multi-factors catalog.',
      enrolledError: 'Unable to fetch your enrolled factors.'
    };
  }

  getPhoneLabels() {
    return {
      setupCountryCodeLabel: 'Country Code',
      setupPhoneNubmerLabel: 'Phone Number',
      setupDescription: 'Please enter the phone number you would like to associate with multi-factor authentication.',
      setupSMSBtn: 'Verify With SMS',
      setupVoiceBtn: 'Verify With Voice',
      setupCodeFailed: 'There was an error setting up this factor',
      title: 'Phone Numbers',
      description: 'By adding your phone number here, you understand that you will be required to use MFA, along with your password, to log in to Benefit Manager Toolkit.',
      createBtn: 'Add Number',
      noEnrolled: 'No phone numbers set for MFA',
      verifyEditDescription: 'Entered the wrong number?'
    };
  }
}
