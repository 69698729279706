import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FamilyEnrollmentComponent } from './family-enrollment.component';
import { Lux } from '@roosevelt/common-ui-lib';
import { IClientPlanDetails, IMassActionState } from '../store/massActions/state';
import { IMemberState } from '../store/member/state';
import { Observable, Subscription } from 'rxjs';
import { getAddMemberFeed } from '../store/member/selector';
import { getSelectedAdminPlan } from '../login/store/selectors';
import { ILoginState } from '../login/store/state';
import { IAppAuthorizations, IUiAuthorizations } from 'src/app/authorizations/state';
import { getStrictUiAuth } from 'src/app/authorizations/selector';
import { ClientUiAuthSerive } from '@roosevelt/client-ui-lib';
import { EligUiAuthSerive } from '@roosevelt/elig-ui-lib';
import { getContractResponse } from '../store/massActions/selector';
import { Router } from '@angular/router';

@Component({
  selector: 'bmt-family-enrollment',
  template: '<ng-container #familyEnrollmentComponent></ng-container>'
})
export class FamilyEnrollmentContainer implements OnInit, OnDestroy {
  luxId = 'BMT-FamilyEnrollment-Component' + this.constructor.name;
  @ViewChild('familyEnrollmentComponent', {
    read: ViewContainerRef,
    static: true
  })
  componentArea: ViewContainerRef;
  componentInstance: FamilyEnrollmentComponent;
  memberStoreSub: Subscription;
  getAddMemberFeed$: Observable < any > = this.lux.get(getAddMemberFeed, this.luxId);
  getContractResponse$: Observable < IClientPlanDetails > = this.lux.get(getContractResponse, this.luxId);
  selectedAdminPlan$: Observable < string > = this.lux.get(getSelectedAdminPlan, this.luxId);
  strictAuth$: Observable < IUiAuthorizations | {} > = this.lux.get(getStrictUiAuth, this.luxId);
  contractResponse$: Observable<IClientPlanDetails> = this.lux.get(getContractResponse, this.luxId);
  eligReasons: { subscriber: { active: any; }; };

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private clientUiAuth: ClientUiAuthSerive,
    private eligUiAuth: EligUiAuthSerive,
    private route: Router,
    private lux: Lux < {
      massAction: IMassActionState,
      member: IMemberState,
      login: ILoginState,
      appUiAuth: IAppAuthorizations
    } > ) {}

  ngOnInit() {
    this._loadComponent();
    this.getAddMemberFeed$.subscribe(feed => this.componentInstance.familyEnrollDefaults = feed);
    this.getContractResponse$.subscribe(clientDetails => this.componentInstance.clientDetails = clientDetails);
    // this.memberStoreSub = this.componentInstance.setMemberStore.subscribe(x => {
    //   this.lux.set(state => state.member, { ...x });
    // });
    this.contractResponse$.subscribe(contractData => this.setUpFamilyEnrollDefaultDropdowns(contractData));
    this.selectedAdminPlan$.subscribe(x => this.componentInstance.selectedAdminPlan = x);
    this.strictAuth$.subscribe((uiAuth: IUiAuthorizations) => {
      this.eligUiAuth.setStrictAuthorizations(uiAuth.eligibility);
    });
    this.componentInstance.setMemberStore.subscribe(storeData => {
      let event = storeData.event;
      delete storeData.event;
      this.lux.set(state => state.member, {
        ...storeData
      });
      this.componentInstance.displaySsnForm = false;
      if (typeof event !== 'string') {
        this.route.navigate(['details']);
      }
    })
  }

  setUpFamilyEnrollDefaultDropdowns(contractResposnse) {
    if(contractResposnse) {
      this.componentInstance.contractResposnse = contractResposnse as IClientPlanDetails;
      const contract = contractResposnse && contractResposnse.client && contractResposnse.client.subClient && contractResposnse.client.subClient.contract ? contractResposnse.client.subClient.contract : null;
      const eligReasonConfig = contract ? contract.eligibilityStatusReasonConfig : null;
      if (eligReasonConfig) {
        this.eligReasons = {
          subscriber: {
            active: this.createDropDownOptions(eligReasonConfig.activeSubscriberEligStatusReasonTypes)
          }
        }
        this.componentInstance.eligStatusReasons = this.eligReasons['subscriber'].active;
      }
      const qualifierConfig = contract && contract.eligibilityConfig && contract.eligibilityConfig.specialAttributeTypes ? this.createDropDownOptions(contract.eligibilityConfig.specialAttributeTypes) : null;
      this.componentInstance.qualifierTypes = qualifierConfig && !!qualifierConfig.length ? qualifierConfig.filter(qualifier => qualifier.label === 'Retiree' || qualifier.label === 'COBRA') : [];
      const memberBenefitConfig = contract && contract.benefitsConfig && contract.benefitsConfig.memberBenefitTypes ? this.createDropDownOptions(contract.benefitsConfig.memberBenefitTypes) : null;
      this.componentInstance.memberBenefitTypes = memberBenefitConfig && !!memberBenefitConfig.length ? memberBenefitConfig : [];
    }
  }

  createDropDownOptions(reasons: any) {
    return reasons.map(x => {
      return x = {
        value: x.code,
        label: x.value
      };
    });
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      FamilyEnrollmentComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;
  }

  ngOnDestroy(): void {}
}
