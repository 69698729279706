import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import { INotices } from 'src/app/store/reports/state';
import * as momentNS from 'moment';
import { MONTHS } from '../../utils/constants';
import { AuthorizationsAsync } from 'src/app/authorizations/authorizations-async';
import { startWith } from 'rxjs/operators';
import { Subject } from 'rxjs';

const moment = momentNS;

@Component({
  templateUrl: './overage-dependent.component.html',
  styleUrls: ['./overage-dependent.component.scss']
})
export class OverageDependentComponent implements OnInit {

  monthMask = { guide: false, mask: [/[0-1]/, /[0-9]/] };
  yearMask = { guide: false, mask: [/[1-2]/, /[0,9]/, /[0,1,2,8,9]/, /[0-9]/] };
  dateMask = { guide: false, mask: [...this.monthMask.mask, '/', /[0-3]/, /[0-9]/, '/', ...this.yearMask.mask] };
  // which report are we showing
  reportType: 'Notice' | 'Adhoc Report' | 'Term Consolidated Report';
  // all fields found on each report
  allReportForm: UntypedFormGroup;
  // fetching anything
  isFetching = false;
  // errors from anything
  errors = '';
  selectedSubClients = [];
  // result from the first call with filters
  notices: INotices[] = [];
  // result from adhoc report
  adhocReportUrl: SafeResourceUrl;
  selectedAdminPlan='';
  isFormSubmitted = false;
  searchFormWidthCssClass = "col-md-6";
  initialAsOfDate = moment(new Date()).add(1,'days').format('MM/DD/YYYY');
  resetSubject: Subject<void> = new Subject<void>();
  @Output() processFormData = new EventEmitter<any>();
  @Output() resetFormData = new EventEmitter<any>();
  @Output() getODNoticeDoc = new EventEmitter<any>();
  payerOptions: string[];
  yearsOptions = [];
  monthOptions = MONTHS;
  sanContent: any;
  consolidatedReportUrl: SafeResourceUrl;

  constructor(private fb: UntypedFormBuilder,  private uiAuthAsync: AuthorizationsAsync,) {
  }

  ngOnInit() {
    this.createForm();
    this.allReportForm.valueChanges.subscribe(()=>{
      this.notices = [];
      this.adhocReportUrl = '';
      this.consolidatedReportUrl = '';
      this.isFormSubmitted = false;
    })
  }

  createForm() {
    const year = moment().year();
    for (let i = 0; i <= 5; i++) {
      this.yearsOptions.push(year - i);
    }
    this.allReportForm = this.fb.group({
      planAcronym: ['', Validators.required],
      clientSpecifiedId: ['', Validators.required],
      subclientSpecifiedId: [{ value: '', disabled: this.reportType === 'Term Consolidated Report' }, Validators.required],
      asOf: [{ value: this.initialAsOfDate, disabled: this.reportType !== 'Adhoc Report' }, Validators.required],
      startOfAgeRange: [{ value: '', disabled: this.reportType !== 'Adhoc Report' }, Validators.required],
      uptoAgeRange: [{ value: '', disabled: this.reportType !== 'Adhoc Report' }, Validators.required],
      month: [{ value: '', disabled: this.reportType !== 'Term Consolidated Report' }, Validators.required],
      year: [{ value: '', disabled: this.reportType !== 'Term Consolidated Report' }, Validators.required]
    }, { validator: this.reportsValidations() });
  }

  reportsValidations() {
    return (form: UntypedFormGroup) => {
      const asOf = form.get('asOf');
      if (asOf.valid) {
        moment(asOf.value).isBefore(moment()) ? asOf.setErrors({ mindate: moment(new Date()).format('MM/DD/YYYY') }) : asOf.setErrors(null);
      }
    };
  }

  setFormState() {
    this.resetReportForm();
    switch (this.reportType) {
      case 'Adhoc Report':
        ['asOf', 'startOfAgeRange', 'uptoAgeRange', 'subclientSpecifiedId'].forEach(key => this.allReportForm.controls[key].enable());
        ['month', 'year'].forEach(key => this.allReportForm.controls[key].disable());
        this.searchFormWidthCssClass = "col-md-9";
        // this.allReportForm.controls.subclientSpecifiedId.addValidators(Validators.required);
        break;
      case 'Notice':
        ['asOf', 'startOfAgeRange', 'uptoAgeRange', 'month', 'year'].forEach(key => this.allReportForm.controls[key].disable());
        ['subclientSpecifiedId'].forEach(key => this.allReportForm.controls[key].enable());
        this.searchFormWidthCssClass = "col-md-6";
        // this.allReportForm.controls.subclientSpecifiedId.addValidators(Validators.required);
        break;
      case 'Term Consolidated Report':
        ['asOf', 'startOfAgeRange', 'uptoAgeRange', 'subclientSpecifiedId'].forEach(key => this.allReportForm.controls[key].disable());
        ['month', 'year'].forEach(key => this.allReportForm.controls[key].enable());
        this.searchFormWidthCssClass = "col-md-3";
        // this.allReportForm.controls.subclientSpecifiedId.removeValidators(Validators.required);
        break;
      default:
        break;
    }

    this.resetReportForm();
  }

  resetReportForm() {
    this.allReportForm.setValue({
      planAcronym: '',
      clientSpecifiedId: '',
      subclientSpecifiedId: '',
      asOf: this.initialAsOfDate,
      startOfAgeRange: '19',
      uptoAgeRange: '99',
      month: moment().format('MM'),
      year: this.yearsOptions[0]
    });
    this.resetSubject.next();
  }

  resetField(event){
    if(event === 'groupInfo'){
      this.allReportForm.controls["clientSpecifiedId"].setValue('');
    }
    this.allReportForm.controls["subclientSpecifiedId"].setValue('');
  }

  setClientId(clientInfo){
    console.log("Selected Client", clientInfo);
    this.allReportForm.controls["planAcronym"].setValue(clientInfo.planAcronym);
    this.allReportForm.controls["clientSpecifiedId"].setValue(clientInfo.specifiedId);
    this.allReportForm.controls["subclientSpecifiedId"].setValue('');
  }
  getSubClientDetails(clientInfo) {
    if(clientInfo && clientInfo.length){
      this.allReportForm.controls["clientSpecifiedId"].setValue(clientInfo?.[0]?.client?.specifiedId); //TODO this shouldn't be needed individual elements should be tied
      this.allReportForm.controls["subclientSpecifiedId"].setValue(clientInfo?.[0]?.specifiedId);
      this.allReportForm.controls["asOf"].setValue(clientInfo?.[0]?.asOfDate);
    }
  }
  setAllSubClients(){
    this.allReportForm.controls["subclientSpecifiedId"].setValue('ALL_SUB_CLIENTS');
  }

  searchFormSubmitted() {
    if (this.allReportForm.valid) {
      this.isFormSubmitted = true;
      this.resetFormData.emit(true);
      this.processFormData.emit(this.allReportForm.value);
    }
  }
  setAsOfDate(asOfDate){
    this.allReportForm.controls["asOf"].setValue(asOfDate);

  }
}
