import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { getSelectedAdminPlan } from '../login/store/selectors';
import { Lux } from '@roosevelt/common-ui-lib';
import { ILoginState } from '../login/store/state';

@Component({
  selector: 'app-dentist-directory',
  templateUrl: './dentist-directory.component.html',
  styleUrls: ['./dentist-directory.component.scss']
})
export class DentistDirectoryComponent implements OnInit, OnDestroy {
  luxId = 'BMT-DentistDirectory-comp' + this.constructor.name;
  selectedAdminPlan: string;

  constructor(private lux: Lux<{ login: ILoginState }>) {
  }

  selectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);

  ngOnInit() {
    this.selectedAdminPlan$.subscribe(x => this.selectedAdminPlan = x);
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }
}
