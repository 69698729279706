import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, } from '@angular/router';
import { Observable } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib';
import { filter, first, map, tap } from 'rxjs/operators';
import { IAuthInfo, ILoginState } from '../login/store/state';
import { getUserInfo } from '../login/store/selectors';
import { getRelaxedUiAuth } from 'src/app/authorizations/selector';
import { IAppAuthorizations, IUiAuthorizations } from 'src/app/authorizations/state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  guard$: Observable<IAuthInfo> = this.lux.get(getUserInfo);
  relaxedAuth$: Observable<IUiAuthorizations | {}> = this.lux.get(getRelaxedUiAuth);
  isLoggedIn: boolean;

  constructor(private router: Router, private lux: Lux<{ login: ILoginState, appUiAuth: IAppAuthorizations }>) {
  }

  // Mapping routes with features
  authGuardRouteMapping = {
    '/eligibility/member': 'manageeligibility',
    '/eligibility/add-member': 'manageeligibility',
    '/eligibility/family-enrollment': 'manageeligibility',
    '/details': 'manageeligibility',
    '/add-member': 'manageeligibility',
    '/family-enrollment': 'manageeligibility',
    '/benefits': 'benefitsInquiry',
    '/reports': 'reports',
    '/billing': 'billing',
    '/summary-of-changes': 'summaryOfChanges',
    '/clientknowledge': 'clientknowledge',
    '/dentist': 'dentistDirectory',
    '/sat': 'sat',
    '/userAdmin/create': 'userAdmin',
    '/userAdmin/manage': 'userAdmin',
    '/manage-user': 'userAdmin',
    '/profile/authorizations': 'profile',
    '/profile/updatePassword': 'profile',
    '/profile/mfa': 'profile',
    '/help': 'profile'
  };

  canActivate(
    next: ActivatedRouteSnapshot,
    snapshot: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.guard$.pipe(
      first(),
      // map(info => info && (new Date().valueOf() / 1000) < (info.tokenValidDuration + info.additionalInfo.auth_time)),
      map(info => {
        let leftNavFeatures = [];
        // Check if the user has authorizations, if not auth guard won't allow the user to the route.
        this.relaxedAuth$.subscribe((uiAuth: IUiAuthorizations) => {
          if (uiAuth && uiAuth.leftnav) {
            leftNavFeatures = ['profile', ...uiAuth.leftnav];
          }
        }).unsubscribe();
        this.isLoggedIn = (info && (new Date().valueOf() / 1000) < info.expirationTime);
        return this.isLoggedIn && leftNavFeatures.includes(this.authGuardRouteMapping[snapshot.url]);
      }),
      tap(allowed => {
        if (!allowed) {
          if (!this.isLoggedIn) {
            this.router.navigate(['/login']);
          } else {
            this.router.navigate(['/notAuthorized']);
          }
        }
      })
    );
  }
}
