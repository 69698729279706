import { Injectable } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib';
import { IRegistrationState } from './state';
import { RegistrationApiService } from './api.service';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegistrationAsync {

  constructor(private lux: Lux<{ registration: IRegistrationState }>, private registrationApiService: RegistrationApiService) {
  }

  getValidationToken(req): Observable<any> {
    return of(req).pipe(
      first(),
      tap(() => this.lux.set(state => state.registration, { isFetching: true })),
      mergeMap(x => this.registrationApiService.getValidationToken(x)),
      tap(x => this.lux.set(state => state.registration, { isFetching: false })),
      catchError((err: HttpErrorResponse) => {
        this.lux.set(x => x.registration, { isFetching: false });
        return of(err.error.apiErrorList.map(item => item.errorMessage).join('\n'));
      })
    );
  }

  getAdministrators(req): Observable<any> {
    return of(req).pipe(
      first(),
      tap(() => this.lux.set(state => state.registration, { isFetching: true })),
      mergeMap(x => this.registrationApiService.getAdministrators(x)),
      tap(x => this.lux.set(state => state.registration, { isFetching: false })),
      catchError((err: HttpErrorResponse) => {
        this.lux.set(x => x.registration, { isFetching: false });
        return of(err.error.apiErrorList.map(item => item.errorMessage).join('\n'));
      })
    );
  }
}
