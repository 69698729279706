import { Injectable } from '@angular/core';
import { mergeMap, catchError, tap, first } from 'rxjs/operators';
import { CreateManageUserService } from './api.service';
import { of } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib';
import { IAdminClients, ICreateManageUserState, initialCreateManageUserState } from './state';
import { HttpErrorResponse } from '@angular/common/http';
import { getAdminAndUserAuthsReq, getAdminAndUserAuthsReqForView, getAdminRoleReq, getAdminUserListReq, getCreateUserReq, getManageUserAuthsReq } from './selector';
import * as momentNS from 'moment';
import { ISolutionsState } from 'src/app/login/store/state';
import { Router } from '@angular/router';

const moment = momentNS;

@Injectable({
  providedIn: 'root'
})
export class CreateManageUserAsync {

  createUser() {
    return this.lux.get(getCreateUserReq).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState, {
          isUserGettingCreated: true,
          errors: '',
          userCreated: false
        })),
      mergeMap(req => this.createManageUser.createUser(req)),
      tap(response => {
        if (response.status === 201) {
          this.lux.set(state => state.createManageUserState, {
            isUserGettingCreated: false,
            errors: '',
            userCreated: true
          });
        } else {
          this.lux.set(state => state.createManageUserState, {
            isUserGettingCreated: false,
            errors: 'Internal System Error',
            userCreated: true
          });
        }
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState, {
          isUserGettingCreated: false,
          errors: errorMessage,
          userCreated: false
        });
        return of(errorMessage);
      })
    );
  }

  getUsersForAdmin() {
    return this.lux.get(getAdminUserListReq).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState, {
          isFetchingUsers: true,
          errorsFetchingUsers: ''
        })),
      mergeMap(req => this.createManageUser.getUsersForAdmin(req)),
      tap(response => {
        this.lux.set(state => state.createManageUserState, {
          isFetchingUsers: false,
          errorsFetchingUsers: '',
          usersOfAdmin: response
        });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState, {
          isFetchingUsers: false,
          errorsFetchingUsers: errorMessage
        });
        return of(errorMessage);
      })
    );
  }

  getLoggedInUserRole() {
    return this.lux.get(getAdminRoleReq).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState, {
          isFetchingUsers: true,
          errorsFetchingUsers: ''
        })),
      mergeMap(req => this.createManageUser.getUsersForAdmin(req)),
      tap(response => {
        this.lux.set(state => state.createManageUserState, {
          isFetchingUsers: false,
          errorsFetchingUsers: '',
          adminRole: response.users
        });
        this.lux.set(state => state.createManageUserState.manageUser, {
          adminRole: response && response.users.length ? response.users[0].roleName : ''
        });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState, {
          isFetchingUsers: false,
          errorsFetchingUsers: errorMessage
        });
        return of(err);
      })
    );
  }

  getLoggedInUserContext(level, groupOrSubgroupAdmin) {
    return of('').pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState.adminClientsInfo, {
          fetchingClients: true,
          errors: '',
          adminClients: []
        })),
      mergeMap(req => this.createManageUser.getLoggedInUserContext(level)),
      tap((response: IAdminClients[]) => {
        response = response.filter(context => context.PLAN !== 'INVLDCTX' || groupOrSubgroupAdmin && context.CLIENT !== 'INVLDCTX');
        this.lux.set(state => state.createManageUserState.adminClientsInfo, {
          fetchingClients: false,
          errors: '',
          adminClients: response
        });
        if (response.length) {
          this.lux.set(state => state.createManageUserState.manageUser, {
            contextOfAdmin: (response.length && groupOrSubgroupAdmin) ? response[0].ADMINPLAN + '/' + response[0].PLAN + '/' + response[0].CLIENT : response[0].ADMINPLAN
          });
        } else {
          // If user doesn't have plans assigned redirect to login and show error message
          this.lux.set(state => state.solutionsLogin, {
            noContext: "You don't have plan assigned, contact your admin for access"
          });
          sessionStorage.clear();
          this.router.navigate(['/login']);
        }
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState.adminClientsInfo, {
          fetchingClients: false,
          errors: errorMessage,
          adminClients: []
        });
        return of(err);
      })
    );
  }

  getLoggedInUserWholeContext() {
    return of('').pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState.manageUser, {
          contextOfAdmin: ''
        })),
      mergeMap(req => this.createManageUser.getLoggedInUserWholeContext()),
      tap(response => {
        this.lux.set(state => state.createManageUserState.manageUser, {
          contextOfAdmin: response.length ? response[0].context : ''
        });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (
          err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0
        ) {
          err.error.apiErrorList.forEach(item => {
            errorMessage += item.errorMessage + '\n';
          });
        } else {
          errorMessage = err.error;
        }
        this.lux.set(state => state.createManageUserState.manageUser, {
          contextOfAdmin: ''
        });
        return of(err);
      })
    );
  }

  getAdminAndUserAuths(purpose) {
    return this.lux.get(purpose === 'view' ? getAdminAndUserAuthsReqForView : getAdminAndUserAuthsReq).pipe(
      first(),
      tap(() =>
        (purpose === 'view') ? this.lux.set(state => state.createManageUserState.viewAdminAndUserAuths, {
            fetchingAuths: true,
            errors: ''
          }) :
          this.lux.set(state => state.createManageUserState.adminAndUserAuths, {
            fetchingAuths: true,
            errors: ''
          })
      ),
      mergeMap(req => this.createManageUser.getAdminAndUserAuthorizations(req)),
      tap(response => {
        (purpose === 'view') ? this.lux.set(state => state.createManageUserState.viewAdminAndUserAuths, {
            fetchingAuths: false,
            errors: '',
            authResponse: response
          }) :
          this.lux.set(state => state.createManageUserState.adminAndUserAuths, {
            fetchingAuths: false,
            errors: '',
            authResponse: response
          });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        (purpose === 'view') ? this.lux.set(state => state.createManageUserState.viewAdminAndUserAuths, {
            fetchingAuths: false,
            errors: errorMessage
          }) :
          this.lux.set(state => state.createManageUserState.adminAndUserAuths, {
            fetchingAuths: false,
            errors: errorMessage
          });
        return of(err);
      })
    );
  }


  manageUserAuthorizations() {
    return this.lux.get(getManageUserAuthsReq).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.createManageUserState.manageUserAuths, {
          updatingAuths: true,
          errors: '',
          authResponse: ''
        })),
      mergeMap(req => this.createManageUser.manageUserAuthorizations(req)),
      tap(response => {
        this.lux.set(state => state.createManageUserState.manageUserAuths, {
          updatingAuths: false,
          errors: '',
          authResponse: 'Authorizations are updated'
        });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState.manageUserAuths, {
          updatingAuths: false,
          errors: errorMessage,
          authResponse: ''
        });
        return of(err);
      })
    );
  }

  getClients(req) {
    const request = {
      planAcronym: req.planAcronym,
      specifiedId: '*',
      name: '',
      status: null,
      pagination: {
        offset: req.offset,
        limit: req.limit,
      },
      subClient: {
        specifiedId: '*'
      },
      sort: [{
        order: 'ASC',
        fieldName: 'specifiedId'
      }]
    };
    return of('').pipe(
      first(),
      tap(() => this.lux.set(state => state.createManageUserState.clientsForAmdin, {
        isFetchingClient: true,
        clientOrSubClientSearchErrors: '',
        clientsInfo: {
          clients: [],
          pagination: {
            limit: 0,
            offset: 0
          },
          sort: []
        }
      })),
      mergeMap(dummy => this.createManageUser.getClients(request)),
      tap(results => {
        this.lux.set(state => state.createManageUserState.clientsForAmdin, {
          isFetchingClient: false,
          clientsInfo: results,
          clientOrSubClientSearchErrors: ''
        });
      }),
      catchError(err => {
        // let errorMessage =
        // if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length) {
        //   err.error.apiErrorList.forEach(x => {
        //     errorMessage += x.errorMessage + '\n';
        //   });
        // }
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState.clientsForAmdin, {
          isFetchingClient: false,
          clientOrSubClientSearchErrors: errorMessage,
          clientsInfo: {
            clients: [],
            pagination: {
              limit: 0,
              offset: 0
            },
            sort: []
          }
        });
        return of(err);
      })
    );
  }


  updateUserInfo(userInfo) {
    return of('').pipe(
      first(),
      tap(() => this.lux.set(state => state.createManageUserState.updatingUser, {
        updatingUser: true,
        errors: '',
        success: ''
      })),
      mergeMap(dummy => this.createManageUser.updateUserInfo(userInfo)),
      tap(results => {
        this.lux.set(state => state.createManageUserState.updatingUser, {
          updatingUser: false,
          errors: '',
          success: 'user data updated'
        });
      }),
      catchError(err => {
        let errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';

        if (err && err.status === 304) {
          errorMessage = 'user info has not modified';
        }
        this.lux.set(state => state.createManageUserState.updatingUser, {
          updatingUser: false,
          errors: errorMessage,
          success: ''
        });
        return of(err);
      })
    );
  }

  enableDisableUser(userID, disable) {
    const request = {
      userIdentifier: userID,
      status: disable ? 'Inactive' : 'Active'
    };
    return of('').pipe(
      first(),
      tap(() => this.lux.set(state => state.createManageUserState.updatingUser, {
        updatingUser: true,
        errors: '',
        success: ''
      })),
      mergeMap(dummy => this.createManageUser.updateUserInfo(request)),
      tap(results => {
        this.lux.set(state => state.createManageUserState.updatingUser, {
          updatingUser: false,
          errors: '',
          success: disable ? 'disabled' : 'enabled'
        });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState.updatingUser, {
          updatingUser: false,
          errors: errorMessage,
          success: ''
        });
        return of(err);
      })
    );
  }


  getUserInfo(user) {
    const request = {
      userIdentifiers: [user.id],
      userStatus: user.status
    };
    return of('').pipe(
      first(),
      tap(() => this.lux.set(state => state.createManageUserState.selectedUserInfo, {
        fetchingUserInfo: true,
        errors: '',
        userInfo: []
      })),
      mergeMap(dummy => this.createManageUser.getUserInfo(request)),
      tap(results => {
        this.lux.set(state => state.createManageUserState.selectedUserInfo, {
          fetchingUserInfo: false,
          errors: '',
          userInfo: results
        });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.createManageUserState.selectedUserInfo, {
          fetchingUserInfo: false,
          errors: errorMessage,
          userInfo: []
        });
        return of(err);
      })
    );
  }

  constructor(
    private lux: Lux<{
      createManageUserState: ICreateManageUserState,
      solutionsLogin: ISolutionsState
    }>,
    private router: Router,
    private createManageUser: CreateManageUserService,
  ) {
  }
}
