import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';
// import { IConsolidatedReq, IMassActionsReports } from './state';
import { BasicAuthConfig } from '@roosevelt/common-ui-lib';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertsApiService {

  constructor(private http: HttpClient, private config: AppConfig, private internalAuth: BasicAuthConfig) { }

  getBmtAlerts(req) {
    return this.http.post<any>(this.config.bmtAlertsUrl+"?limit=25&offset=0&sort=updatedOn,priority", req);
  }
}
