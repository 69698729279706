import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AuthExclusionInterceptor,
  AuthInterceptor,
  BasicAuthConfig,
  CommonUserService,
  defaultErrorMessageMap,
  DialogModule,
  ErrorMessageMap,
  LoaderModule,
  Lux,
  LuxDevTools,
  LuxHP
} from '@roosevelt/common-ui-lib';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AppConfig } from './shared/app.config';
import { EligEnvConfig, UserAuthConfig } from '@roosevelt/elig-ui-lib';
import { ClientEnvConfig, UserAuthConfig as ClientUserAuth } from '@roosevelt/client-ui-lib';
import { BenefitsEnvConfig } from '@roosevelt/benefits-ui-lib';
import { BMTAuthInterceptor } from './shared/interceptors/auth-interceptor.service';
import { LeftNavContainer } from './left-nav/left-nav.container';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { AlertStateManagementService } from '@roosevelt/alert-management-lib';
import { RegistrationModule } from './registration/registration.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SatConfig } from '@roosevelt/sat-lib';
import { MemberBenefitsConfig } from '@roosevelt/member-benefits-lib';
import { ProcedureConfig } from '@roosevelt/procedures-lib';
import { DentistDirEnvConfig } from '@roosevelt/dentist-dir-ui-lib';
import { UserAuthConfig as BillingUserAuth } from '@roosevelt/billing-lib';
import { BMTAlertsComponent } from './bmtAlerts/bmt-alerts.component';
import { NotificationConfig } from '@roosevelt/notification-lib';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
import { PreviousRouteService } from './shared/services/previousUrl.service';
import { AppUIAuthorizationService, AuthorizationsModule, UIAuthorizationService } from '@roosevelt/authorizations-lib';
import OktaAuth from '@okta/okta-auth-js';

@NgModule({
  declarations: [
    AppComponent,
    LeftNavContainer,
    LeftNavComponent,
    PageNotFoundComponent,
    BMTAlertsComponent,
    NotAuthorizedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    RegistrationModule,
    DialogModule,
    LoaderModule,
    AuthorizationsModule.forRoot()
  ],
  providers: [
    GoogleAnalyticsService,
    LuxHP,
    PreviousRouteService,
    {
      provide: UIAuthorizationService,
      useExisting: AppUIAuthorizationService
    },
    { provide: Lux, useClass: LuxDevTools },
    {
      provide: AppConfig,
      useExisting: BasicAuthConfig
    },
    {
      provide: EligEnvConfig,
      useExisting: AppConfig
    },
    {
      provide: ClientEnvConfig,
      useExisting: AppConfig
    },
    {
      provide: BenefitsEnvConfig,
      useExisting: AppConfig
    },
    {
      provide: DentistDirEnvConfig,
      useExisting: AppConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      deps: [BasicAuthConfig, OktaAuth],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BMTAuthInterceptor,
      deps: [BasicAuthConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExclusionInterceptor,
      deps: [BasicAuthConfig, OktaAuth],
      multi: true
    },
    {
      provide: ErrorMessageMap,
      useValue: {
        ...defaultErrorMessageMap,
        maxdate: (maxdate: string) => `Date must be below ${maxdate}`,
        mindate: (mindate: string) => `Date must be above ${mindate}`,
        endangered: () => 'Member does not have endangered attribute',
        ssnMissmatch: () => `Member ID's don't match`,
        emailMissMatch: () => `Emails don't match`,
        password: () => `Should be 10-25 characters long and contain at least one of each: upper case, lower case, numeric and one of the following special characters !@$%^&*()?.,`,
        userName: () => `Should begin with 2 alpha characters and must be between 5-25 characters long and can contain only the following special characters -.@_`,
        accountNumberMissMatch: () => `Account Numbers don't match`,
        routingNumberMissMatch: () => `Routing Numbers don't match`,
        historicalReportsEndDate: () => `Must be above From Date.`,
        consolidatedClaimInvoice: () => `Must be adjacent to From Date Month.`,
        consolidatedNCClaimInvoiceStartDate: () => `Must be first day of the Month.`,
        consolidatedNCClaimInvoiceEndDate: () => `Must be last day of the From Date Month.`,
        nonConsolidatedClaimInvoice: () => `Must be same month as From Date.`,
      }
    },
    {
      provide: 'alertManagementConfig',
      useValue: {}
    },
    AlertStateManagementService,
    {
      provide: SatConfig,
      useExisting: AppConfig
    },
    {
      provide: MemberBenefitsConfig,
      useExisting: AppConfig
    },
    {
      provide: ProcedureConfig,
      useExisting: AppConfig,
    },
    {
      provide: UserAuthConfig,
      useValue: {
        userAuthorizations: {}
      }
    },
    {
      provide: ClientUserAuth,
      useValue: {
        userAuthorizations: {}
      }
    },
    {
      provide: BillingUserAuth,
      useValue: {
        userAuthorizations: {}
      }
    },
    {
      provide: 'authConfig',
      useFactory: (env: AppConfig) => {
        return {
          USER_PROFILE_URL: env.userProfileUrl,
          cacheKey: 'bmt-ui-authorizations'
        };
      },
      deps: [AppConfig]
    },
    {
      provide: NotificationConfig,
      useFactory: (env: AppConfig) => {
        const userInfo: any = sessionStorage.getItem('auth-user-info') ? JSON.parse(sessionStorage.getItem('auth-user-info')) : {};
        return {
          firstName: userInfo.firstName, lastName: userInfo.lastName, userId: userInfo.id, email: 'ClaimAdminGoLiveSupport@deltadentalmi.com', region: env.region
        };
      },
      deps: [AppConfig]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
