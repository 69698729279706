import { Component, Inject } from '@angular/core';
import { AppConfig } from '../../shared/app.config';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'bmt-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent {

  changePwdUrl: string;

  constructor(@Inject(DOCUMENT) private docRef: Document, private env: AppConfig) {
    this.changePwdUrl = docRef.location.pathname.split('/')[1].toLowerCase() === 'ren' ? env.renChangePasswordUrl : env.changePasswordUrl;
  }

  updatePassword() {
    sessionStorage.setItem('baseUrl', this.docRef.location.origin + '/' + this.docRef.location.pathname.split('/')[1]);
    this.docRef.location.href = this.changePwdUrl;
  }
}
