import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { LeftNavComponent } from './left-nav.component';
import { Observable, Subscription } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib';
import { ILoginState } from 'src/app/login/store/state';
import { NavigationEnd, Router } from '@angular/router';
import { IAppAuthorizations, IUiAuthorizations } from '../authorizations/state';
import { getRelaxedUiAuth } from '../authorizations/selector';
import { FeatureComponent } from 'src/app/utils/feature-component';
import { LeftNavigation } from 'src/app/features/left-nav';

@Component({
  selector: 'app-left-nav',
  template: `<ng-container #leftNavComponent></ng-container>`
})

export class LeftNavContainer extends FeatureComponent<typeof LeftNavigation> implements OnInit, OnDestroy {
  luxId = 'BMT-LeftNavContainer' + new Date().toISOString();
  relaxedAuth$: Observable<IUiAuthorizations | {}> = this.lux.get(getRelaxedUiAuth, this.luxId);
  @ViewChild('leftNavComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: LeftNavComponent;
  router$: Subscription;
  userAdminRoutes = ['/userAdmin/create', '/userAdmin/manage', '/manage-user'];
  eligRoutes = ['/eligibility/add-member', '/eligibility/member', '/eligibility/family-enrollment'];
  profileRoutes = ['/profile/authorizations', '/profile/updatePassword'];
  constructor(private router: Router,
              private componentFactoryResolver: ComponentFactoryResolver,
              private lux: Lux<{ login: ILoginState, appUiAuth: IAppAuthorizations }>) {
    super([]);
  }

  ngOnDestroy(): void {
    this.router$.unsubscribe();
  }

  ngOnInit() {
    this.loadComponent();
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LeftNavComponent);
    this.componentArea.clear();
    this.componentInstance = this.componentArea.createComponent(componentFactory).instance;
    this.relaxedAuth$.subscribe((uiAuth: IUiAuthorizations) => {
      if (Object.keys(uiAuth).length) {
        this.componentInstance.navFeatures = ['profile', ...uiAuth.leftnav];
      } else {
        this.componentInstance.navFeatures = [];
      }
    });
    // this.componentInstance.eligActive = (this.router.url === '/eligibility/add-member' || this.router.url === '/eligibility/member' || this.router.url === '/eligibility/family-enrollment');
    this.router$ = this.router.events.subscribe(event => {
      if (event && (event instanceof NavigationEnd)) {
        this.componentInstance.eligActive = this.eligRoutes.includes(this.router.url);
        this.componentInstance.userAdminActive = this.userAdminRoutes.includes(this.router.url);
        this.componentInstance.profileActive = this.profileRoutes.includes(this.router.url);
      }
    });
  }
}
