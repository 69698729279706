import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ReportsAsync } from '../../store/reports/async';
import { Lux } from '@roosevelt/common-ui-lib';
import { ICustomGroupReportRes, IReportsState } from '../../store/reports/state';
import { Observable } from 'rxjs';
import { getSelectedAdminPlan } from '../../login/store/selectors';
import { ILoginState } from '../../login/store/state';
import { errorGetCustomGroupReport, errorGetDocument, getCustomGroupReportRes, isFetchingCustomGroupReport } from '../../store/reports/selector';
import { UserAuthConfig } from '@roosevelt/elig-ui-lib';
import { CustomGroupReportComponent } from 'src/app/reports/custom-group-report/custom-group-report.component';
import { initialReportsState } from 'src/app/store/reports/initialState';


@Component({
  selector: 'bmt-custom-group-report',
  template: '<ng-container #customGroupReportComponent></ng-container>'
})
export class CustomGroupReportContainer implements OnInit, OnDestroy {
  luxId = 'bmt-custom-group-report' + this.constructor.name;

  @ViewChild('customGroupReportComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: CustomGroupReportComponent;


  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private reportsAsync: ReportsAsync,
              private lux: Lux<{ reportsState: IReportsState, login: ILoginState }>,
              private authConfig: UserAuthConfig) {
  }

  customGroupError$: Observable<string> = this.lux.get(errorGetCustomGroupReport, this.luxId);
  errorGetDocument$: Observable<string> = this.lux.get(errorGetDocument, this.luxId);
  getCustomGroupReportRes$: Observable<ICustomGroupReportRes> = this.lux.get(getCustomGroupReportRes, this.luxId);
  isFetchingCustomGroupReport$: Observable<boolean> = this.lux.get(isFetchingCustomGroupReport, this.luxId);
  selectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);

  ngOnInit() {
    this._loadComponent();
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      CustomGroupReportComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;

    this.selectedAdminPlan$.subscribe(x => this.componentInstance.selectedAdminPlan = x);
    this.isFetchingCustomGroupReport$.subscribe(x => this.componentInstance.isFetching = x);
    this.getCustomGroupReportRes$.subscribe(x => this.componentInstance.customGroupReports = x);
    this.customGroupError$.subscribe(x => this.componentInstance.customGroupError = x);
    this.errorGetDocument$.subscribe(x => this.componentInstance.customGroupError = x);
    this.componentInstance.getCustomGroupReport.subscribe(this.getCustomGroupReport.bind(this));
    this.componentInstance.selectedReport.subscribe(this.getGroupReport.bind(this));
    this.componentInstance.resetReport.subscribe(this.resetReport.bind(this));
  }

  getCustomGroupReport(value) {
    const req = {
      planAcronym: this.componentInstance.allSubClientFlow ? this.componentInstance.selectedClient.planAcronym : this.componentInstance.selectedSubClients[0].planAcronym,
      clientSpecifiedId: this.componentInstance.allSubClientFlow ? this.componentInstance.selectedClient.specifiedId : this.componentInstance.selectedSubClients[0].client.specifiedId,
      subClientSpecifiedId: this.componentInstance.allSubClientFlow ? 'ALL' : this.componentInstance.selectedSubClients[0].specifiedId,
      fromDate: this.componentInstance.customGroupForm.value.fromDate,
      toDate: this.componentInstance.customGroupForm.value.toDate,
      frequency: this.componentInstance.customGroupForm.value.frequency,
      pagination: {
        limit: 25,
        offset: value
      },
      sort: [{
        order: 'DESC',
        fieldName: 'toDate'
      }]
    };
    this.lux.set(state => state.reportsState.customGroupReportReq, req);
    this.reportsAsync.getCustomGroupReports().subscribe();
  }

  getGroupReport(req) {
    req.type === 'PDF' ? this.reportsAsync.getPDFDocument(req).subscribe() : this.reportsAsync.getExcelDocument(req).subscribe();
  }

  resetReport() {
    this.componentInstance.showResults = false;
    this.lux.set(state => state.reportsState, initialReportsState);
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
    this.componentInstance.customGroupForm$.unsubscribe();
  }

}
