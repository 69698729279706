import { ISummaryOfChangesState } from './state';

export const initialStateSummaryOfChange: ISummaryOfChangesState = {
  isFetchingTransactions: false,
  summaryOfChangeError: '',
  transactionsReq: {
    pagination: {
      offset: 0,
      limit: 100
    },
    searchCriteria: {
      payer: '',
      groupSpecifiedIdentifier: '',
      subgroupSpecifiedIdentifier: '',
      startDate: '',
      endDate: '',
      sessionId: '',
    }
  },
  sessionTransactionsRes: {
    totalRecords: 0,
    transactions: [ {
      transactionType: '',
      sessionId: '',
      transactionTime: '',
      userName: '',
      transactionStatus: '',
      transactionId: 0,
      memberId: '',
      personId: 0,
      payer: '',
      groupId: '',
      subgroupId: '',
      applicationName: '',
      subscriberEnrollmentId: 0,
    } ]
  },
  searchTransactionsRes: {
    totalRecords: 0,
    transactions: [ {
      transactionType: '',
      sessionId: '',
      transactionTime: '',
      userName: '',
      transactionStatus: '',
      transactionId: 0,
      memberId: '',
      personId: 0,
      payer: '',
      groupId: '',
      subgroupId: '',
      applicationName: '',
      subscriberEnrollmentId: 0,
    } ]
  },
  selectedTransaction: {
    transactionType: '',
    sessionId: '',
    transactionTime: '',
    userName: '',
    transactionStatus: '',
    transactionId: 0,
    memberId: '',
    personId: 0,
    payer: '',
    groupId: '',
    subgroupId: '',
    applicationName: '',
    subscriberEnrollmentId: 0,
  },
  isFetchingDetails: false,
  memberEligHistoryRes: [ {
    eligibility: {
      receivedDate: '',
      effectiveDate: '',
      throughDate: '',
      isWaitExempt: false,
      eligStatusType: {
        code: '',
        value: '',
        static_identifier: '',
      },
      eligStatusReasonType: {
        code: '',
        value: '',
        static_identifier: '',
      },
      clientSpecifiedIdentifier: '',
      subclientSpecifiedIdentifier: '',
      waitExempt: false
    },
    transaction: {
      auditActionType: {
        code: '',
        value: '',
        static_identifier: '',
        description: '',
      },
      date: '',
      userId: '',
      applicationName: '',
    }
  }
  ],
  cobHistoryRes: [ {
    coordinationOfBenefit: {
      endDate: '',
      paymentOrderType: {
        code: '',
        value: '',
        static_identifier: '',
      },
      receivedDate: '',
      startDate: '',
    },
    transaction: {
      auditActionType: {
        code: '',
        value: '',
        static_identifier: '',
        description: '',
      },
      date: '',
      userId: '',
      applicationName: '',
    }
  } ],
  coverageHistoryRes: [ {
    coverage: {
      clientSpecifiedIdentifier: '',
      coverageType: {
        code: '',
        value: '',
        static_identifier: '',
      },
      effectiveDate: '',
      planAcronym: '',
      receivedDate: '',
      subclientSpecifiedIdentifier: '',
    },
    transaction: {
      auditActionType: {
        code: '',
        value: '',
        static_identifier: '',
        description: '',
      },
      date: '',
      userId: '',
      applicationName: '',
    }
  } ],
  personHistoryRes: [ {
    personHistory: {
      person: {
        dateOfBirth: '',
        firstName: '',
        suffix: '',
        gender: '',
        lastName: '',
        middleName: '',
        prefix: '',
        singleNameInd: '',
      }
    },
    transaction: {
      auditActionType: {
        code: '',
        value: '',
        static_identifier: '',
        description: '',
      },
      date: '',
      userId: '',
      applicationName: '',
    }
  } ],
  addressHistoryRes: [ {
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      county: '',
      poBox: '',
      state: '',
      suiteNumber: '',
      zip: '',
    },
    transaction: {
      auditActionType: {
        code: '',
        value: '',
        static_identifier: '',
        description: '',
      },
      date: '',
      userId: '',
      applicationName: '',
    }
  } ],
};
