import { Injectable } from '@angular/core';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { AlertsApiService } from './api.service';
import { of } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib';
import { HttpErrorResponse } from '@angular/common/http';
import { IAlertsState } from './state';

@Injectable({
  providedIn: 'root'
})
export class AlertsAsync {
  constructor(
    private lux: Lux<{ bmtAlertsState: IAlertsState}>,
    private alertsService: AlertsApiService,
  ) { }

  getBMTAlerts(req) {
      return of(req).pipe (
      first(),
      tap(() =>
        this.lux.set(state => state.bmtAlertsState, { isFetchingAlerts: true })),
      mergeMap(x => this.alertsService.getBmtAlerts(req)),
      tap(results => {
        this.lux.set(state => state.bmtAlertsState, { alerts: results, isFetchingAlerts: false });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map(err => err.errorMessage).join('\n');
        }
        this.lux.set(state => state.bmtAlertsState, { errors: errorMessage, isFetchingAlerts: false });
        return of(errorMessage);
      })
    );
  }
}
