import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AppConfig } from '../../shared/app.config';
import { IAdminAndUserAuthResponse, IAdminAndUserAuthsReq, IAdminUserList, ICreateUserReq, IManageUserAuthsReq, IUserListReq } from './state';
import { IClients, IClientSearchCriteria } from '@roosevelt/client-ui-lib/lib/client-search/store/state';

@Injectable({
  providedIn: 'root'
})
export class CreateManageUserService {

  constructor(private http: HttpClient, private config: AppConfig) { }
  private handleResponse(r) { return r; }

  createUser(req: ICreateUserReq) {
    return this.http.post<any>(this.config.createUserUrl, req, {
      observe: 'response'
    });
  }

  getUsersForAdmin(req: IUserListReq) {
    return this.http.post<IAdminUserList>(this.config.listOfUserForAdminUrl, req, {
      observe: 'body'
    });
  }

  getLoggedInUserContext(contextLevel) {
    return this.http.post<any>(this.config.userContextUrl, {level:contextLevel}, {
      observe: 'body'
    });
  }

  getLoggedInUserWholeContext() {
    return this.http.get<any>(this.config.userWholeContextUrl);
  }

  getAdminAndUserAuthorizations(req: IAdminAndUserAuthsReq) {
    return this.http.post<IAdminAndUserAuthResponse>(this.config.adminAndUserAuthorizationsUrl, req, {
      observe: 'body'
    });
  }

  manageUserAuthorizations(req: IManageUserAuthsReq) {
    return this.http.post<any>(this.config.manageUserAuthorizationsUrl, req, {
      observe: 'body'
    });
  }

  getClients(searchReq) {
    return this.http.post<IClients>(this.config.clientSearchUrl, searchReq);
  }

  enableDisableUser(req, disable) {
    return this.http.post<any>(disable ? this.config.disableUser : this.config.enableUser, req, {
      observe: 'body'
    });
  }

  updateUserInfo(req) {
    return this.http.patch<any>(this.config.updateUserInfoScimUrl, req, {
      observe: 'body'
    });
  }

  getUserInfo(req) {
    return this.http.post<any>(this.config.userInfoUrl, req, {
      observe: 'body'
    });
  }
}
