import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib';
import { MemberDetailsComponent } from './member-details.component';
import { IMemberState } from '../store/member/state';
import { getAddMemberFeed, getDependentInfo, getSelectedSubscriber } from '../store/member/selector';
import { PreviousRouteService } from '../shared/services/previousUrl.service';
import { getSelectedAdminPlan } from '../login/store/selectors';
import { ILoginState } from '../login/store/state';
import * as momentNS from 'moment';
import { IAppAuthorizations, IUiAuthorizations } from 'src/app/authorizations/state';
import { EligUiAuthSerive } from '@roosevelt/elig-ui-lib';
import { ClientUiAuthSerive } from '@roosevelt/client-ui-lib';
import { getStrictUiAuth } from 'src/app/authorizations/selector';

const moment = momentNS;

@Component({
  selector: 'app-member-details',
  template: '<ng-container #memberDetailsComponent></ng-container>'
})
export class MemberDetailsContainer implements OnInit, OnDestroy {
  luxId = 'BMT-MemberDetails-Comp'+this.constructor.name;
  @ViewChild('memberDetailsComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: MemberDetailsComponent;
  memberStoreSub: Subscription;

  constructor(private clientUiAuth: ClientUiAuthSerive,
              private eligUiAuth: EligUiAuthSerive,
              private componentFactoryResolver: ComponentFactoryResolver,
              private routerService: PreviousRouteService,
              private lux: Lux<{ member: IMemberState, login: ILoginState, appUiAuth: IAppAuthorizations }>) {
  }

  selectedMember$: Observable<any> = this.lux.get(getSelectedSubscriber, this.luxId);
  getDependentInfo$: Observable<any> = this.lux.get(getDependentInfo, this.luxId);
  getAddMemberFeed$: Observable<any> = this.lux.get(getAddMemberFeed, this.luxId);
  selectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);
  strictAuth$: Observable<IUiAuthorizations | {}> = this.lux.get(getStrictUiAuth, this.luxId);

  ngOnInit() {
    this._loadComponent();
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      MemberDetailsComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;
    this.strictAuth$.subscribe((uiAuth: IUiAuthorizations) => {
      this.eligUiAuth.setStrictAuthorizations(uiAuth.eligibility);
      this.clientUiAuth.setStrictAuthorizations(uiAuth.client);
    });
    this.selectedMember$.subscribe(x => {
      const fromEligRoute = this.componentInstance.showBackToResults = !(this.routerService.previousUrl.includes('/add-member') || this.routerService.previousUrl.includes('/family-enrollment'));
      const isPageRefreshed = (this.routerService.previousUrl === '/details');
      this.getAddMemberFeed$.subscribe(y => {
        // Just for refresh condition to show back to results link
        (isPageRefreshed) && (this.componentInstance.showBackToResults = x.planAcronym ? true : false);
        // This object gets values only when page gets refreshed
        const DecidingClientInfoNeeded = (isPageRefreshed) ? {
          planAcronym: x.planAcronym || y.planAcronym,
          clientId: x.clientSpecifiedIdentifier || y.clientId,
          subClientId: x.subclientSpecifiedIdentifier || y.subClientId,
          asOf: x.asOf || y.asOf
        } : null;
        this.componentInstance.clientSelected = {
          planAcronym: !DecidingClientInfoNeeded ? (!fromEligRoute ? y.planAcronym : x.planAcronym) : DecidingClientInfoNeeded.planAcronym,
          clientSpecifiedId: !DecidingClientInfoNeeded ? (!fromEligRoute ? y.clientId : x.clientSpecifiedIdentifier) : DecidingClientInfoNeeded.clientId,
          subClientSpecifiedId: !DecidingClientInfoNeeded ? (!fromEligRoute ? y.subClientId : x.subclientSpecifiedIdentifier) : DecidingClientInfoNeeded.subClientId,
          asOf: !DecidingClientInfoNeeded ? (!fromEligRoute ? y.asOf : moment(x.eligibilityEffectiveDate).isAfter(moment(x.asOf || new Date()).format('YYYY-MM-DD')) ? x.eligibilityEffectiveDate : x.asOf) : DecidingClientInfoNeeded.asOf
        };
      });
      this.getDependentInfo$.subscribe(y => {
        this.componentInstance.memberSelected = y.dependentAdded ? y.value : {
          name: {
            first: x.firstName,
            last: x.lastName
          }
        };
      });
    });
    this.memberStoreSub = this.componentInstance.setMemberStore.subscribe(x => {
      this.lux.set(state => state.member, { ...x });
    });
    this.selectedAdminPlan$.subscribe(x => {
      this.componentInstance.selectedAdminPlan = x;
    });
  }

  ngOnDestroy(): void {
    this.lux.set(state => state.member, { selectedDependent: {}, dependentAdded: false });
    // tslint:disable-next-line: no-unused-expression
    this.memberStoreSub && this.memberStoreSub.unsubscribe();
    this.lux.destroy(this.luxId);
  }
}
