import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberDetailsComponent } from './member-details.component';
import { MemberDetailsModule as MDLibModule, TransferCopyMemberModule as TransferCopyMember } from '@roosevelt/elig-ui-lib';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MemberDetailsContainer } from './member-details.container';
import { PreviousRouteService } from '../shared/services/previousUrl.service';
import { ClientDetailsModule } from '@roosevelt/client-ui-lib';
@NgModule({
    declarations: [MemberDetailsComponent, MemberDetailsContainer],
    imports: [
        CommonModule,
        MDLibModule,
        BrowserAnimationsModule,
        TransferCopyMember,
        ClientDetailsModule
    ],
    providers: [
        PreviousRouteService
    ]
})
export class MemberDetailsModule {}
