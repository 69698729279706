import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib';
import { Observable } from 'rxjs';
import { CreateManageUserAsync } from 'src/app/store/createManageUser/async';
import { errorFetchingAuthsToView, getAdminAndUserAuthsResposneForView, getAdminClients, isFetchingAuthsToView } from 'src/app/store/createManageUser/selector';
// import { getUserLists, getUsesrlistErrors, isFetchingUserLists } from 'src/app/store/createManageUser/selector';
import { IAdminAndUserAuthResponse, IAdminAndUserAuthsReq, IAdminClients, IAdminUserList, ICreateManageUserState, IManageuser, IManageUserAuthObj, IManageUserAuthsReq, initialCreateManageUserState, IUserList } from 'src/app/store/createManageUser/state';

@Component({
  selector: 'bmt-view-auth-at-group',
  templateUrl: './view-auth-at-group-level.component.html',
  styleUrls: ['./view-auth-at-group-level.component.scss']
})
export class ViewAuthAtGroupComponent implements OnInit,OnDestroy {
  luxId = 'BMT-view-auth-at-group'+this.constructor.name+new Date().toISOString();
  @Input() userContext: string;
  @Input() selectedUserId: string;
  @Input() userInfo: IManageuser;
  getErrors$:Observable<string> = this.lux.get(errorFetchingAuthsToView, this.luxId);
  isFetchingAuthsToView$:Observable<boolean> = this.lux.get(isFetchingAuthsToView, this.luxId);
  getUserAuthsToview$:Observable<IAdminAndUserAuthResponse> = this.lux.get(getAdminAndUserAuthsResposneForView, this.luxId);
  isFetchingUserList: boolean;
  totalAuths:number = 0;
  // currentPage = 1;
  defaultPageSize = 100;
  selectedIndex = 0;
  sortField: string = '';
  sortOrder: string = 'ASC';
  editAuthorizations = false;
  errors: string;
  fetchingAuths: boolean;
  userAndAdminAuths: IAdminAndUserAuthResponse;
  adminClientsInfo: IAdminClients[];
  adminSubGroupAuths: {};
  singleClientOrSubGroupAdmins = ['BMT-CLIENT-ASSOCIATION-ADMIN', 'BMT-CLIENT-ASSOCIATION-DELEGATE', 'BMT-GROUP-ADMIN', 'BMT-SUBGROUP-ADMIN', 'BMT-GROUP-DELEGATE', 'BMT-SUBGROUP-DELEGATE'];

  constructor(private lux: Lux<{createManageUserState: ICreateManageUserState}>, private createManageUserAsync: CreateManageUserAsync) { }
  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }
  
  ngOnInit(): void {
    const initailState = initialCreateManageUserState.viewAdminAndUserAuths.authResponse;
    this.lux.set(state => state.createManageUserState.viewAdminAndUserAuths, {
      authResponse: initailState
    });
    this.getErrors$.subscribe(err => this.errors = err);
    this.isFetchingAuthsToView$.subscribe(fetchingAuths => this.fetchingAuths = fetchingAuths);
    this.getUserAuthsToview$.subscribe(adminAndUserAuths => {
      this.userAndAdminAuths = adminAndUserAuths;
      if(adminAndUserAuths.userRelatedAuthorizations && adminAndUserAuths.userRelatedAuthorizations.filter(auths => auths.subGroupSpecifiedIdentifier).length) {
        // adminAndUserAuths.userRelatedAuthorizations && (this.userAndAdminAuths.userRelatedAuthorizations = adminAndUserAuths.userRelatedAuthorizations.filter(userAuths => userAuths.userPrivilegeSelected));
        const objectToConsider = !this.singleClientOrSubGroupAdmins.includes(this.userInfo.adminRole) ? adminAndUserAuths.adminRelatedAuthorizations.subGroupAuthorizations : adminAndUserAuths.userRelatedAuthorizations;
        this.adminSubGroupAuths = this.createAdminSubGrpAuthsObj(objectToConsider);
        // adminAndUserAuths.adminRelatedAuthorizations && adminAndUserAuths.adminRelatedAuthorizations.subGroupAuthorizations ? adminAndUserAuths.adminRelatedAuthorizations.subGroupAuthorizations.reduce((bag, key) => ({
        //   ...bag,
        //   [key.subGroupSpecifiedIdentifier]: key
        // }), {}) : {};
        // // console.log(this.adminSubGroupAuths);
        // const noSubGrpAuthCount = ((adminAndUserAuths.pagination.totalRecords > this.defaultPageSize) && this.userAndAdminAuths.userRelatedAuthorizations.length) ? (this.defaultPageSize - this.userAndAdminAuths.userRelatedAuthorizations.length) : 0;
        // this.totalAuths =  (noSubGrpAuthCount === 0 && adminAndUserAuths.pagination.totalRecords > this.defaultPageSize) ? (adminAndUserAuths.pagination.totalRecords - noSubGrpAuthCount) : 0;
      }
      this.totalAuths =  adminAndUserAuths.pagination.totalRecords;
    });
    this.fetchUserAuthorizations(0);
  }

  createAdminSubGrpAuthsObj(authsToConsider) {
    const authAtSubGroups = authsToConsider.length ? authsToConsider.reduce((bag, key) => ({
      ...bag,
      [key.subGroupSpecifiedIdentifier]: !this.singleClientOrSubGroupAdmins.includes(this.userInfo.adminRole) ? key : this.userAndAdminAuths.adminRelatedAuthorizations
    }), {}) : {};
    return authAtSubGroups;
  }

  fetchUserAuthorizations(offset) {
    const userContextStr = this.userContext.split('/');
    const adminAndUserAuthReq: IAdminAndUserAuthsReq = {
      userIdentifier: this.selectedUserId,
      payer: userContextStr[1],
      payerAdmin: userContextStr[0],
      groupAccessLevel: 'one',
      groupSpecifiedIdentifier: userContextStr[2],
      ...userContextStr[3] && {subGroupSpecifiedIdentifier: userContextStr[3]},
      pagination: {
        offset: offset > this.totalAuths ? (offset - this.totalAuths) : offset,
        limit: this.defaultPageSize
      },
      sort: [
        {
          fieldName: "specifiedId",
          order: "ASC"
        }
      ]
    };
    this.lux.set(state => state.createManageUserState.viewAdminAndUserAuths,{authRequest: adminAndUserAuthReq});
    this.createManageUserAsync.getAdminAndUserAuths('view').subscribe();
  }

  getMoreAuths(page) {
    // console.log(page);
    // this.currentPage = page.pageIndex;
    this.fetchUserAuthorizations(page.pageIndex >= 1 ? (page.pageIndex * this.defaultPageSize) : 0);
  }
}