import { AbstractControl } from '@angular/forms';

export const matchSsns = (cntrl1, cntrl2) => {
    return (control: AbstractControl): { [key: string]: any } => {
        const confirmSsn = control.value;
        if (confirmSsn) {
            const ssnCntrl = control.root.get(cntrl1).value;
            const cSsnCntrl = control.root.get(cntrl2).value;
            if (confirmSsn !== ssnCntrl) {
                return { 'ssnMissmatch': true };
            } else if (cSsnCntrl !== '' && cSsnCntrl !== confirmSsn) {
                return { 'ssnMissmatch': true };
            }
        }
    };
};

// const isPassword = (value) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value);
const isPassword = (value) => /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()?.,])[A-Za-z\d!@#$%^&*()?.,]{10,25}$/.test(value);
export const isValidPassword = (control: AbstractControl): { [key: string]: any } => {
  const fieldValue = control.value;
  if (fieldValue && isPassword(fieldValue) === false) {
    return { password: true };
  }
};

const userName = (value) => /^((?=[a-zA-Z]{2,})(?=[0-9]*)(?=[.@_-]*))[A-Za-z0-9.@_-]{5,25}$/.test(value);
export const isUserName = (control: AbstractControl): { [key: string]: any } => {
  const fieldValue = control.value;
  if (fieldValue && userName(fieldValue) === false) {
    return { userName: true };
  }
};

export const matchPasswords = (control: AbstractControl): { [key: string]: any } => {
  const confirmPassword = control.value;
  if (confirmPassword) {
    const password = control.root.get('password').value;
    const cPassword = control.root.get('confirmPassword').value;
    if (confirmPassword !== password) {
      return { mismatch: true };
    } else if (cPassword !== '' && cPassword !== confirmPassword) {
      return { mismatch: true };
    }
  }
};

export const matchEmail = (cntrl1, cntrl2) => {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const emailCntrl = control.root.get(cntrl1);
      const cEmailCntrl = control.root.get(cntrl2);
      if (cEmailCntrl.value !== '' && cEmailCntrl.value !== emailCntrl.value) {
        return { 'emailMissMatch': true };
      } else if (cEmailCntrl.value === emailCntrl.value) {
        emailCntrl.setErrors(null);
        cEmailCntrl.setErrors(null);
      }
    }
  };
};
export const matchAccountOrRoutingNumber = (cntrl1, cntrl2) => {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      const accCntrl = control.root.get(cntrl1);
      const cAccCntrl = control.root.get(cntrl2);
      if (cntrl1 === 'accountNumber') {
        if (cAccCntrl.value !== '' && cAccCntrl.value !== accCntrl.value) {
          return { accountNumberMissMatch: true };
        } else if (cAccCntrl.value === accCntrl.value) {
          accCntrl.setErrors(null);
          cAccCntrl.setErrors(null);
        }
      } else if (cntrl1 === 'routingNumber') {
        if (cAccCntrl.value !== '' && cAccCntrl.value !== accCntrl.value) {
          return { routingNumberMissMatch: true };
        } else if (cAccCntrl.value === accCntrl.value) {
          accCntrl.setErrors(null);
          cAccCntrl.setErrors(null);
        }
      }
    }
  };
};

