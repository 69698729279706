import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-clientknowledge',
  templateUrl: './clientknowledge.component.html',
  styleUrls: ['./clientknowledge.component.scss']
})
export class ClientknowledgeComponent implements OnInit {

  @Output() openClientKnowledge = new EventEmitter<any>();

  isFetching: string;
  error: string;

  constructor() {
  }

  ngOnInit(): void {
    this.emitOpenClientKnowledge();
  }
  emitOpenClientKnowledge() {
    this.openClientKnowledge.emit();
  }
}
