import { Injectable } from "@angular/core";
import { mergeMap, catchError, tap, first } from "rxjs/operators";
import { MassActionsApiService } from "./api.service";
import { of } from "rxjs";
import { Lux } from "@roosevelt/common-ui-lib";
import { IMassActionState } from "./state";
import { HttpErrorResponse } from "@angular/common/http";
import { getMassTerminateOrReinstateReq, getMassTransferReq, getContractRequestObj } from './selector';

@Injectable({
  providedIn: "root"
})
export class MassActionAsync {

  massTerminateOrReinstate(action) {
    return this.lux.get(getMassTerminateOrReinstateReq).pipe(
      first(),
      tap(() =>
        this.lux.set(x => x.massAction, { isCallingMassAction: true, errorMessage: '', isMassActionCallDone: false })),
      mergeMap(x => this.massService.massTerminateOrReinstate(x, action)),
      tap(results => {
        this.lux.set(x => x.massAction, {
          isCallingMassAction: false,
          isMassActionCallDone: results.status || results,
          errorMessage: ''
        });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (
          err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0
        ) {
          err.error.apiErrorList.forEach(item => {
            errorMessage += item.errorMessage + '\n';
          });
        }
        this.lux.set(x => x.massAction, { isCallingMassAction: false, errorMessage: errorMessage });
        return of(errorMessage);
      })
    );
  }

  massTransfer() {
    return this.lux.get(getMassTransferReq).pipe(
      first(),
      tap(() =>
        this.lux.set(x => x.massAction, { isCallingMassAction: true, errorMessage: '', isMassActionCallDone: false })),
      mergeMap(x => this.massService.massTransfer(x)),
      tap(results => {
        this.lux.set(x => x.massAction, {
          isCallingMassAction: false,
          isMassActionCallDone: results.status || results,
          errorMessage: ''
        });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (
          err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0
        ) {
          err.error.apiErrorList.forEach(item => {
            errorMessage += item.errorMessage + '\n';
          });
        }
        this.lux.set(x => x.massAction, { isCallingMassAction: false, errorMessage: errorMessage });
        return of(errorMessage);
      })
    );
  }

  getContractInfo() {
    return this.lux.get(getContractRequestObj).pipe(
      first(),
      tap(() =>
        this.lux.set(x => x.massAction.contractInfo, { isFecthingContractInfo: true, errors: '', responseObj: undefined})),
      mergeMap(x => this.massService.getContractInfo(x)),
      tap(results => {
        this.lux.set(x => x.massAction.contractInfo, { isFecthingContractInfo: false, errors: '', responseObj: results});
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (
          err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0
        ) {
          err.error.apiErrorList.forEach(item => {
            errorMessage += item.errorMessage + '\n';
          });
        }
        this.lux.set(x => x.massAction.contractInfo, { isFecthingContractInfo: false, errors: errorMessage, responseObj: undefined});
        return of(errorMessage);
      })
    );
  }

  constructor(
    private lux: Lux<{
      massAction: IMassActionState
    }>,
    private massService: MassActionsApiService,
  ) { }
}
