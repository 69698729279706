import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib';
import { Observable } from 'rxjs';
import { getSelectedAdminPlan } from '../login/store/selectors';
import { ILoginState } from '../login/store/state';
import * as momentNS from 'moment';
import { BillingComponent } from './billing.component';
import { IBmtBillingState } from '../store/billing/state';
import { BmtBillingAsync } from '../store/billing/async';
import { getEbillError, isFetching } from '../store/billing/selector';
import { IAppAuthorizations, IUiAuthorizations } from '../authorizations/state';
import { getRelaxedUiAuth } from '../authorizations/selector';

const moment = momentNS;

@Component({
  selector: 'app-billing',
  template: '<ng-container #BillingComponent></ng-container>'
})

export class BillingContainer implements OnInit, OnDestroy {

  luxId = 'BMT-Billing-Comp'+this.constructor.name;
  @ViewChild('BillingComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: BillingComponent;
  selectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);
  ebillError$:Observable<string> = this.lux.get(getEbillError, this.luxId);
  isFetching$:Observable<string> = this.lux.get(isFetching, this.luxId);
  relaxedAuth$: Observable<IUiAuthorizations | {}> = this.lux.get(getRelaxedUiAuth, this.luxId);
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver, private BBAsync: BmtBillingAsync,
    private lux: Lux<{ billingState: IBmtBillingState, login: ILoginState,appUiAuth: IAppAuthorizations }>) {
  }

  ngOnInit(): void {
    this._loadComponent();
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      BillingComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;
    this.relaxedAuth$.subscribe(uiAuth => {
      this.componentInstance.billingAttributeFeatures = (uiAuth as IUiAuthorizations).billing})

    this.selectedAdminPlan$.subscribe(x => {
      this.componentInstance.selectedAdminPlan = x;
    });
    this.ebillError$.subscribe(error => {
      this.componentInstance.ebillError = error;
    });
    this.isFetching$.subscribe(fetching => {
      this.componentInstance.isFetching = fetching;
    });
    this.componentInstance.insertEbillContext.subscribe(this.insertEbillContext.bind(this));
  }

  ngOnDestroy() {
    this.lux.destroy(this.luxId);
  }

  insertEbillContext() {
    const req = {
      ebillUserContext: {
        payer: this.componentInstance.selectedClient.planAcronym,
        groupIdentifier: this.componentInstance.selectedClient.specifiedId,
        subgroupIdentifier: this.componentInstance.selectedSubClient,
      }
    }
    this.lux.set(state => state.billingState, { ebillRequest: req });
    this.BBAsync.insertEbillContext().subscribe(response => {
      if (response && response.status === 201) {
        const url = this.componentInstance.peopleSoftUrl;
        window.open(url, "_blank");
        this.componentInstance.resetSelected();
      }
    });
  }
}
