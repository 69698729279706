<div class="row pg-style bmt-profile-container">
    <div class="col-sm-12 pg-style">
        <div class="row search-banner">
        <div class="col-sm-12 search-banner-col">
            <h2 class="h3 search-banner-title">Profile</h2>
        </div>
        </div>
        <div class="row search-nav">
        <div class="col-sm-12 search-nav-col">
          <ul class="list-inline search-nav-list">
            <li><a routerLink="/profile/authorizations" [class.search-nav-active]="page === 'authorizations'">Authorizations</a></li>
            <li><a routerLink="/profile/updatePassword" [class.search-nav-active]="page === 'updatePassword'">Password</a></li>
            <li><a *ngIf="enableMfa && !isImpersonating" routerLink="/profile/mfa" [class.search-nav-active]="page === 'mfa'">Login Authentication</a></li>
          </ul>
        </div>
        </div>
        <div class="row search-comp">
            <div class="card col-sm-12">
                <bmt-profile-authorizations *ngIf="page === 'authorizations'"></bmt-profile-authorizations>
                <bmt-update-password *ngIf="page === 'updatePassword'"></bmt-update-password>
                <lib-multi-factor *ngIf="page === 'mfa'" [multifactorLabels]="getMfaLabels()" [phoneLabels]="getPhoneLabels()"></lib-multi-factor>
            </div>
        </div>
    </div>
</div>
