<div class="sticky" [class.prd-sticky]="region === 'prod'">
  <div class="floating-banner">
    <div>
      <h3 class="member-name">
        {{memberSelected.name.first}} {{memberSelected.name.last}}
        ({{memberSelected.dependentType ? memberSelected.dependentType.value === "Non-Spouse" ? "Dependent" : "Spouse" : "Subscriber"}})
      </h3>
    </div>
    <div>
      <elig-switch-member [key]="'BMT'" [disabled]="disableSwitchMember" (selectedMember)="selectedMember($event)">
      </elig-switch-member>
    </div>
  </div>
</div>
<div class="member-details-section">
  <ng-container *ngIf="showBackToResults">
    <span class="icon-left-arrow-double-grey"></span><a class="backToSearch" (click)="goToMemberSearch()">Back To
      Results</a>
  </ng-container>
  <!-- Client Plan Details Component -->
  <client-plan-details (clientInfo)="clientDetails = $event" [feed]="clientSelected">
  </client-plan-details>
  <ng-container *ngIf="clientDetails">
    <!-- Family Enrollment Component -->
    <elig-family-enrollment [key]="'BMT'"
      [clientDetailsAgeLimit]="clientDetails && clientDetails.client && clientDetails.client.subClient && clientDetails.client.subClient.contract && clientDetails.client.subClient.contract.eligAgeLimitsConfig
      && clientDetails.client.subClient.contract.eligAgeLimitsConfig.child && clientDetails.client.subClient.contract.eligAgeLimitsConfig.child.maxAgeLimit ?
      clientDetails.client.subClient.contract.eligAgeLimitsConfig.child.maxAgeLimit.limit : 0"
      (headerSelected)="$event === 'Coverage Type' ? scroll('coverage_info') : headerSelectedFromFamilySection($event)" (selectedMember)="selectedMember($event)"
      (addDependent)="headerSelectedFromFamilySection($event)" [disableMemberSwitch]="disableSwitchMember">
    </elig-family-enrollment>
    <!-- Member info component -->
    <elig-member-info [key]="'BMT'" [selectedAdminPlan]="selectedAdminPlan" (clientSelected)="transferOrCopyDone($event)"
                      [clientDetails]="clientDetails" (headerSelected)="headerSelectedFromMemberInfo($event)"
                      (selectedMemberInfo) = "selectedMember($event)"
                      [addtionalAttrbituesHeading]="['edit']"
                      [brandLogo]="(clientSelected.planAcronym.toLowerCase() !== 'rlha') ? '../assets/images/logo-delta.svg' : '../assets/images/logo-ren.svg'"
                      [visionLogo]="playerUnderVsp.includes(clientSelected.planAcronym.toUpperCase()) ? '../assets/images/logo-vsp-vision.svg' : ''">
    </elig-member-info>
    <!-- Coverage Info Component -->
    <elig-coverage-info [key]="'BMT'" id="coverage_info"></elig-coverage-info>
  </ng-container>
</div>
